import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LanguageService } from '../language.service';
import { AggregateService } from '../aggregate.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  lang: any = null;
  actions = ['refresh', 'add', 'delete']

  @Input()
  page = "";

  @Input()
  msg = "";

  @Input()
  loading = false;

  @Output('action')
  public action: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public languageService: LanguageService,
    public aggregateService: AggregateService,
    public authService: AuthService
  )
  {}

  ngOnInit() {
    this.lang = this.languageService.language_data;
  }

  public emitAction(action: string)
  {
    this.action.emit(action);
  }

}
