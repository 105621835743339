import { Component, Inject, OnInit } from '@angular/core';
import  {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval } from 'rxjs';
import { AggregateService } from '../aggregate.service';
import { LanguageService } from '../language.service';


@Component({
  selector: 'select-aggregate-dialog',
  templateUrl: './select-aggregate-dialog.component.html',
})
export class SelectAggregateDialogComponent implements OnInit {

  public prev_keyword: string = "";
  public keyword: string = "";
  public result: any;
  public search_result = [];
  private updateInterval = interval(100);
  private updateSubscription;

  public lang: any;
  
  constructor(public aggregateService: AggregateService,
    public languageService: LanguageService,
    public dialogRef: MatDialogRef<SelectAggregateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.updateSubscription = this.updateInterval.subscribe(x => { this._update() })
      
    }

  ngOnInit()
  {
    this.lang = this.languageService.language_data;
    this.init()
  }

  public async init()
  {
    var aggregates = await this.aggregateService.getAggregates(this.data.sys_name, this.data._class, 0);
    if(this.data.filter)
    {
      this.search_result = [];
      for(let a of aggregates)
      {
        if (a[this.data.filter[0]] == this.data.filter[1])
        {
          this.search_result.push(a)
        }
      }
    }
    else
    {
      this.search_result = aggregates;
    }
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public on_close(): void 
  {
    this.dialogRef.close(this.result);
  }

  // run find on server
  private async _update()
  {
    if (this.prev_keyword != this.keyword)
    {
      this.prev_keyword = this.keyword;
      if(!this.keyword)
      {
        this.init();
      }
      else
      {
        var aggregates = await this.aggregateService.find(this.keyword, this.data.sys_name, this.data._class);
        if(this.data.filter)
        {
          this.search_result = [];
          for(let a of aggregates)
          {
            if (a[this.data.filter[0]] == this.data.filter[1])
            {
              this.search_result.push(a)
            }
          }
        }
        else
        {
          this.search_result = aggregates;
        }
      }
      
    }
  }
}