import { Component, Inject, OnInit } from '@angular/core';
import  {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../language.service';
import { Attribute } from "../datatypes"

@Component({
  selector: 'app-add-attribute-dialog',
  templateUrl: './add-attribute-dialog.component.html',
  styleUrls: ['./add-attribute-dialog.component.css']
})
export class AddAttributeDialogComponent implements OnInit {
  public lang: any;
  public attribute: Attribute = new Attribute("", "", "string", "");
  public read = true;
  public msg: string = "";

  constructor(
    public languageService: LanguageService,
    public dialogRef: MatDialogRef<AddAttributeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit()
  {
    this.lang = this.languageService.language_data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void
  {
    this.msg = "";
    var result = {"read": false, "attribute": null};
    
    if(this.attribute.type == "number")
    {
      this.attribute.value = 0;
    }
    
    if(this.attribute.type == "boolean")
    {
      this.attribute.value = false;
    }

    if(this.attribute.default)
    {
      this.attribute.value = this.attribute.default;
    }

    if(!this.attribute.name)
    {
      this.msg = "t_no_name";
      return;
    }

    result.read = this.read;
    result.attribute = this.attribute;
    this.dialogRef.close(result)
  }
}
