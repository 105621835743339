import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from './datatypes';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseService
{
  private user: User;
  public users = [];
  public users_by_id = {};
  public search_result = [];

  constructor(protected http: HttpClient) {
    super(http, {"user": new User()});
    this.endpoint = this.cfg.API_GATEWAY+'user/';
  }

  // Requests
  // create request to get entity by id from projection
  private __getByEmailProjection(email: string, password: string)
  {
    var post_data = {"auth_id": "", "auth_key": "", "email": "", "password": ""};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    post_data.email = email;
    post_data.password = password
    return this.__request(this.endpoint+"get/auth", post_data, {});
  }

  // ---- Responses Handling ----------------------------------------------------------
  // get server response from aggregate
  private async _getUserByIdAggregate(id: string): Promise<boolean>
  {
    var data = await this.__getByIdAggregate(id).toPromise();
    try
    {
      this.user = this._assignFromJdata<User>(User, data as any);
    }
    catch(e)
    {
      if(this.cfg.DEBUG)
      {
        console.error("No data from server");
      }
    }
    return Promise.resolve(true);
  }

  // Get server response from projection
  private async _getUserByIdProjection(id: string): Promise<boolean>
  {
    var data = await this.__getByIdProjection(id).toPromise();
    try
    {
      this.user = this._assignFromData<User>(User, data as any);
    }
    catch(e)
    {
      if(this.cfg.DEBUG)
      {
        console.error("No data from server");
      }
    }
    return Promise.resolve(true);
  }
  // Get server response from projection
  private async _getUserByEmailProjection(email: string, password: string): Promise<boolean>
  {
    var data = await this.__getByEmailProjection(email, password).toPromise();
    try
    {
      this.user = this._assignFromData<User>(User, data as any);
    }
    catch(e)
    {
      if(this.cfg.DEBUG)
      {
        console.error("No data from server");
      }
    }
    return Promise.resolve(true);
  }

  // Fetch all users from server
  public async _getUsersAggregate(): Promise<boolean>
  {
    var data = await this.__getAggregate().toPromise().then();
    this.users = [];
    this.users_by_id = {};
    (data as any).forEach(
      (u) => {
        try
        {
          var result = this._assignFromJdata<User>(User, u);
          // add to dict
          this.users_by_id[result.id] = result;
          //add to array
          this.users.push(result);
        }
        catch(e)
        {
          if (this.cfg.DEBUG)
          {
            console.error("Server error");
          }
        }
        
    });
    return Promise.resolve(true);
  }

  public async _getFindResult(keyword: string): Promise<boolean>
  {
    var data = await this.__find(keyword).toPromise().then();
    this.search_result = [];
    try
    {
      (data as any).forEach(
        (i) => {
          try
          {
            var result = this._assignFromData<User>(User, i);
            //add to array
            this.search_result.push(result);
          }
          catch(e)
          {
            if (this.cfg.DEBUG)
            {
              console.error("Server error");
            }
          }
          
      });
    }
    catch(e)
    {
      if (this.cfg.DEBUG)
      {
        console.error("Server error");
      }
    }
    return Promise.resolve(true);
  }

  // create/update
  private _updateUser(user: User, command: string)
  {
    this.__update<User>(User, user, command).subscribe();
  }

  //Delete
  private _deleteUser(id: string)
  {
    this.__delete(id).subscribe();
  }



  // ---- Public functions --------------------------------------------------
  //
  public async getUserById(id: string, from: string = 'projection'): Promise<User>
  {
    if(from == 'projection')
    {
      await this._getUserByIdProjection(id);
    }
    else
    {
      await this._getUserByIdAggregate(id);
    }
    
    return this.user;
  }

  // find entities
  public async find(keyword: string): Promise<{}>
  {
      await this._getFindResult(keyword);
      return this.search_result;
  }

  public async getUserByEmail(email: string, password: string, from: string = 'projection'): Promise<User>
  {
    if(from == 'projection')
    {
      await this._getUserByEmailProjection(email, password);
    }
        
    return this.user;
  }

  // create/update
  public updateUser(user: User, command: string)
  {
    this._updateUser(user, command);
  }

  // delete
  public deleteUser(id: string)
  {
    this._deleteUser(id);
  }
}