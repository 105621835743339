import { Attribute, Device } from './datatypes';
import { AuthService } from './auth.service';
import { AggregateService } from './aggregate.service';

export class Tools
{
    public device_types: string[] = ["SLM-4", "SLM-4.5", "SMM-1"];


    public suffleArray(array: any[])
    {
        var d: any[] = [];
        for (let x of array)
        {
            d.push(x);
        }

        for (var c = d.length - 1; c > 0; c--) {
            var b = Math.floor(Math.random() * (c + 1));
            var a = d[c];
            d[c] = d[b];
            d[b] = a;
          }
          return d
    }

    public getType(id: number)
    {
        return this.device_types[id];
    }

    public getAttr(attributes: Attribute[], name: string): any
    {
        var ret: any = null;
        attributes.forEach(
            (e) => 
            {
                if(e.name == name)
                {
                    ret = e.value;
                    
                }
            }
        );
        return ret;
    }

    public getById(list: any[], id: string)
    {
        for(let aggregate of list)
        {
            if( aggregate.id == id)
            {
                return aggregate;
            }
        }
        return '';
    }

    public getByName(list: any[], name: string)
    {
        for(let aggregate of list)
        {
            if( aggregate.name == name)
            {
                return aggregate;
            }
        }
    }

    public delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
      }

    public isBiggerScreen() {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width < 768) {
            return true;
        } else {
            return false;
        }
    }

    public newByType(type: number)
    {
        var device = new Device();
        device.gateway_address = 1;

        if(type == 0 || type == 1)
        {
            device.local_address = 1001;
            
            device.read.push(new Attribute('temp', 'Teplota ovzduší', 'number', 0, '°C', -20, 50))
            device.read.push(new Attribute('humidity', 'Vlhkost ovzduší', 'number', 0, '%', 0, 100))
            device.read.push(new Attribute('pressure', 'Atmosferický tlak', 'number', 0, 'hPa', 500, 1100))
            device.read.push(new Attribute('online', 'On-line', 'boolean', true))

            device.write.push(new Attribute('pwm1', 'Úroveň osvětlení 1', 'number', 0, '%', 0, 100));
            device.write.push(new Attribute('pwm2', 'Úroveň osvětlení 2', 'number', 0, '%', 0, 100));
            device.write.push(new Attribute('power', 'Zapnuto', 'boolean', false,));
        }
        // SMM-1
        // read['volt_l1_n'] = float(parse[0])
        // read['volt_l2_n'] = float(parse[1])
        // read['volt_l3_n'] = float(parse[2])
        // read['volt_avg'] = float(parse[3])
        // read['curr_l1'] = float(parse[4])
        // read['curr_l2'] = float(parse[5])
        // read['curr_l3'] = float(parse[6])
        // read['curr_avg'] = float(parse[7])
        // read['power_l1'] = float(parse[8])
        // read['power_l2'] = float(parse[9])
        // read['power_l3'] = float(parse[10])
        // read['power_all'] = float(parse[11])
        // read['power_factor'] = float(parse[12])
        // read['total_energy'] = int(parse[14])
        // read['frequency'] = float(parse[13])
        if(type == 2)
        {
            device.read.push(new Attribute('online', 'On-line', 'boolean', true))            

            device.read.push(new Attribute('total_energy', 'Spotřeba celkem', 'number', 0, 'kWh', 0, 0))

            device.read.push(new Attribute('volt_l1_n', 'Napětí L1', 'number', 0, 'V', 220, 260))
            device.read.push(new Attribute('volt_l2_n', 'Napětí L2', 'number', 0, 'V', 220, 260))
            device.read.push(new Attribute('volt_l3_n', 'Napětí L3', 'number', 0, 'V', 220, 160))
            device.read.push(new Attribute('volt_avg', 'Napětí (průměr)', 'number', 0, 'V', 220, 240))

            device.read.push(new Attribute('curr_l1', 'Proud L1', 'number', 0, 'A', 0, 16))
            device.read.push(new Attribute('curr_l2', 'Proud L2', 'number', 0, 'A', 0, 16))
            device.read.push(new Attribute('curr_l3', 'Proud L3', 'number', 0, 'A', 0, 16))
            device.read.push(new Attribute('curr_avg', 'Proud (průměr)', 'number', 0, 'A', 0, 16))

            device.read.push(new Attribute('power_l1', 'Výkon L1', 'number', 0, 'kW', 0, 3.86))
            device.read.push(new Attribute('power_l2', 'Výkon L2', 'number', 0, 'kW', 0, 3.86))
            device.read.push(new Attribute('power_l3', 'Výkon L3', 'number', 0, 'kW', 0, 3.86))
            device.read.push(new Attribute('power_all', 'Výkon (celkem)', 'number', 0, 'kW', 0, 11.04))

            device.read.push(new Attribute('power_factor', 'Činitel výkonu', 'number', 0, '', 0, 3))
            device.read.push(new Attribute('frequency', 'Frekvence', 'number', 0, 'Hz', 0, 100))

        }

        device.write.push(new Attribute('auto', 'Automatický režim', 'boolean', false));

        return device;
    }

    public async devicesFromLocation(s: AggregateService, location: string)
    {
        var devices: Device[] = [];
        var _devices: Device[] = await s.getAggregates('device', new Device(), 0)

        for(let d of _devices)
        {
            if(d.location == location)
            {
                devices.push(d);
            }
        }

        return devices;
    }

    public async devicesFromGroup(s: AggregateService, group: string)
    {
        var devices: Device[] = [];
        var _devices: Device[] = await s.getAggregates('device', new Device(), 0);

        for(let d of _devices)
        {
            if(d.groups.includes(group))
            {
                devices.push(d);
            }
        }

        return devices;
    }

    private attExists(target: Attribute[], attr: Attribute): Boolean
    {
        for(let a of target)
        {
            if(a.name == attr.name)
            {
                return true;
            }
        }
        return false;
    }

    private getAttributeByName(target: Attribute[], attr: string): any
    {
        for(let a of target)
        {
            if(a.name == attr)
            {
                return a;
            }
        }
        return false;
    }

    public async getWritesFromGroup(s: AggregateService, group: string)
    {

        var attrs: Attribute[] = [];
        var _devices: Device[] = await s.getAggregates('device', new Device(), 0);

        

        for(let d of _devices)
        {
            if(d.groups.includes(group))
            {
                for(let a of d.write)
                {
                    if(!this.attExists(attrs, a))
                    {
                        if(a.type == 'number' || a.type == 'boolean')
                        {
                            attrs.push(a);
                        }
                    }
                }
            }
        }

        return attrs;
    }

    public async getReadsFromGroup(s: AggregateService, group: string)
    {

        var attrs: Attribute[] = [];
        var _devices: Device[] = await s.getAggregates('device', new Device(), 0);

        

        for(let d of _devices)
        {
            if(d.groups.includes(group))
            {
                for(let a of d.read)
                {
                    if(!this.attExists(attrs, a))
                    {
                        if(a.type == 'number' || a.type == 'boolean')
                        {
                            attrs.push(a);
                        }
                    }
                }
            }
        }

        return attrs;
    }

    public hmToMinutes(t: string)
    {
        var x = t.split(":");

        return (Number(x[0]) * 60) + (Number(x[1])); 
    }

    public minutesToHm(m: number)
    {
        if(m >= 1440)
        {
            m = m - 1440;
        }

        var h = Math.floor(m / 60)

        var m = m % 60;

        return (h + ":" + m);
    }

    public timeSteps(from, to, steps)
    {
        var from_minutes = this.hmToMinutes(from);
        var to_minutes = this.hmToMinutes(to);
        var s = steps - 2;
        var ret = [from];

        if(to_minutes <= from_minutes)
        {
            to_minutes += 24*60;
        }

        console.log("FROM_m", from_minutes);
        console.log("TOM_m", to_minutes);

        if(s > 0)
        {
            var dif = to_minutes - from_minutes;
            var s_m = dif / (s+1);
            var next_time = from_minutes;

            for(let x of [].constructor(s))
            {
                next_time += s_m;
                ret.push(this.minutesToHm(Math.floor(next_time)));
            }

            ret.push(to);

            return ret;
        }
        else
        {
            return [from, to]
        }
    }

    public biggerTime(time1: string, time2: string): boolean
    {
        var t1_h: number = 0;
        var t1_m: number = 0;

        var t2_h: number = 0;
        var t2_m: number = 0;

        t1_h = Number(time1.split(":")[0])
        t1_m = Number(time1.split(":")[1])
        t2_h = Number(time2.split(":")[0])
        t2_m = Number(time2.split(":")[1])

        if(t1_h > t2_h)
        {
        return true;
        }

        if(t1_h < t2_h)
        {
        return false;
        }

        if(t1_m > t2_m)
        {
        return true;
        }

        return false;
    }

    public deviceOnline(device): Boolean
    {
        return this.getAttr(device.read, 'online');
    }
}