import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Device, Location, Devicegroup, Attribute, Schedule } from '../datatypes';
import { Tools } from '../tools';
import { trigger, style, animate, transition } from '@angular/animations';
import { Config } from '../config'
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { AuthService } from '../auth.service';
import { LanguageService } from '../language.service';
import { AggregateService } from '../aggregate.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { v4 as uuid } from 'uuid';
import { MapService } from '../map.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AddScheduleComponent } from '../add-schedule/add-schedule.component';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-devicegroup',
  templateUrl: './devicegroup.component.html',
  styleUrls: ['./devicegroup.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ]
})

export class DevicegroupComponent implements OnInit {
  lang: any;
  level: string = 'user';
  devicegroups: Devicegroup[] = []
  devicegroup_type: number = 0;
  group_devices: Device[];
  locations: Location[] = [];
  all_locations: Location[] = [];
  locations_dict = {};
  selected_devicegroup: Devicegroup;
  selected_location: Location;
  action_msg: string = ""
  tools = new Tools();
  cfg = new Config();
  loading: boolean = true;
  tab_index: number = 0;

  all_attributes: Attribute[] = [];

  locations_loaded: boolean = false;

  tableColumns: string[] = ['reference', 'name', 'priority', 'select']
  tableSource: any;
  tableSelection = new SelectionModel<Devicegroup>(true, []);

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Output("open_device")
  public open_device: EventEmitter<Device> = new EventEmitter<Device>();
  

  constructor(
    public authService: AuthService,
    public languageService: LanguageService,
    public aggregateService: AggregateService,
    public dialog: MatDialog,
    public mapService: MapService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.lang = this.languageService.language_data;
    this.level = this.authService.user.level;

    this.init()
  }

  public async init(update: boolean = false)
  {
    this.loading = true;

    if(!update)
    {
      this.locations_loaded = false;
    }

    this.all_locations = await this.aggregateService.getAggregates('location', new Location(), 0);
    for(let l of this.all_locations)
    {
      this.locations_dict[l.id] = l;
    }

    if(this.level == 'root')
    {
      this.locations = this.all_locations;
    }
    else
    {      
      this.locations = [];
      for(let l of this.all_locations)
      {
        if(this.authService.user.locations_id.includes(l.id))
        {
          this.locations.push(l);
        }
      }

    }

    if(!update)
    {
      var id = this.cookieService.get('selected_location');
      this.selected_location = null
      
      if(id)
      {
        this.tab_index = 0

        for(let l of this.locations)
        {
          if(l.id == id)
          {
            this.selected_location = l;
            break;
          }

          this.tab_index++;
          
        }
        if(!this.selected_location)
        {
          this.selected_location = this.locations[0];
        }
      }
      else
      {
        this.selected_location = this.locations[0];
      }
    }

    var devicegroups: Devicegroup[] = await this.aggregateService.getAggregates('devicegroup', new Devicegroup(), 0);

    this.devicegroups = []
    if(this.selected_location)
    {
      for(let d of devicegroups)
      {
        if (d.location == this.selected_location.id)
        {
          this.devicegroups.push(d);
        }
      }
    }
    else
    {
      this.devicegroups = devicegroups;
    }

    if(!update)
    {
      this.locations_loaded = true;
    }

    
    
    this.updateTable()

    this.loading = false;
  }

  // update mat table data
  private updateTable()
  {
    this.tableSource = new MatTableDataSource(this.devicegroups);
    this.tableSource.sort = this.sort;
    this.tableSource.paginator = this.paginator;
    this.tableSelection = new SelectionModel<Devicegroup>(true, []);
  }

  // table filter
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableSource.filter = filterValue.trim().toLowerCase();

    if (this.tableSource.paginator) {
      this.tableSource.paginator.firstPage();
    }
  }

  // table selection
  public isAllSelected() {
    const numSelected = this.tableSelection.selected.length;
    const numRows = this.tableSource.data.length;
    return numSelected === numRows;
  }

  // table selection
  public masterToggle() {
    this.isAllSelected() ?
        this.tableSelection.clear() :
        this.tableSource.data.forEach(row => this.tableSelection.select(row));
  }

  // update after delay
  public async _update(delay: number = 0)
  {
    await this.tools.delay(delay);
    this.init(true)
    if(this.selected_devicegroup)
    {
      this.selected_devicegroup = await this.aggregateService.getAggregateById(this.selected_devicegroup.id, 'devicegroup', new Devicegroup());
      this.group_devices = await this.tools.devicesFromGroup(this.aggregateService, this.selected_devicegroup.id)
      this.all_attributes = await this.tools.getWritesFromGroup(this.aggregateService, this.selected_devicegroup.id)
    }
  }

  // Hanndle action from header
  public onAction(e)
  {
    this.loading = true;

    this.action_msg = "";
    if(e == "add")
    {
      if(this.level != 'user')
      {
        if(!this.selected_devicegroup)
        {
          this.newDevicegroup();
        }
      }
      else
      {
        this.action_msg = "t_not_allowed";
      }
    }
    else if(e == "delete")
    {
      if(this.selected_devicegroup)
      {
        if(this.level != "user")
        {
          this.confirmDelete();
        }
        else
        {
          this.action_msg = "t_not_allowed";
        }
      }
    }
    else if(e == "refresh")
    {
      this.action_msg = '';
      this._update();
    }

    this.loading = false;
  }

  // callback click on row
  public async select(row)
  {
    this.loading = true;

    if(row.id)
    {
      this.selected_devicegroup = await this.aggregateService.getAggregateById(row.id, 'devicegroup', new Devicegroup())
      this.group_devices = await this.tools.devicesFromGroup(this.aggregateService, this.selected_devicegroup.id)
      this.all_attributes = await this.tools.getWritesFromGroup(this.aggregateService, this.selected_devicegroup.id)
    }

    this.loading = false;
  }

  // new devicegroup 
  public async newDevicegroup()
  {
    this.loading = true;

    var devicegroup = new Devicegroup()
    devicegroup.id = uuid();
    devicegroup.name = "Nová skupina";

    if(this.selected_location)
    {
      devicegroup.location = this.selected_location.id;
    }
    
    this.aggregateService.updateAggregate(devicegroup, 'create', 'devicegroup', new Devicegroup());
    this.devicegroups.push(devicegroup);
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;
  }

  // devicegroup save 
  public saveDevicegroup(devicegroup: Devicegroup)
  {
    this.loading = true;

    if(devicegroup.id)
    {
      this.aggregateService.updateAggregate(devicegroup, 'update', 'devicegroup', new Devicegroup());
    }
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;
  }

  // delete devicegroup
  public deleteDevicegroup(id)
  {
    this.loading = true; 

    this.aggregateService.deleteAggregate(id, 'devicegroup');

    var cnt = 0;
    this.devicegroups.forEach(e => {
      if(e.id == id)
      {
        this.devicegroups.splice(cnt, 1);
      }
      cnt += 1;
    });
    
    this.selected_devicegroup = null;
    this.group_devices = null;
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;

  }

  // confirm of delete
  public confirmDelete()
  {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {aggregate: this.selected_devicegroup, aggregate_name: 'devicegroup'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
      {
        this.deleteDevicegroup(this.selected_devicegroup.id);
      }
    });
  }

  public tabChanged(e)
  {
    this.selected_location = this.locations[e.index];
    this.init(true);
    this.cookieService.set('selected_location', this.selected_location.id, 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax")
  }  

  public addSchedule()
  {

    const dialogRef = this.dialog.open(AddScheduleComponent, {
      width: '400px',
      data: {attributes: this.all_attributes, target: this.selected_devicegroup.schedules}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && this.selected_devicegroup.schedules)
      {
        if(!this.selected_devicegroup.schedules)
        {
          this.selected_devicegroup.schedules = [];
        
        }
        
        this.selected_devicegroup.schedules.push(result);
      }
    });
  }
  
  // Delete schedule
  public deleteSchedule(i: number)
  {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && this.selected_devicegroup.schedules)
      {
        this.selected_devicegroup.schedules.splice(i, 1);
      }
    });

  }

  public scheduleChanged(e)
  {
    console.log(e);
  }

  private updateDeviceAttr(attribute, device)
  {
    if(!device.write)
    {
      return false;
    }

    var idx = 0
    for(let attr of device.write)
    {
      if(attr.name == attribute.name)
      {
        device.write[idx].value = attribute.value;
      }
      idx++;
    }

    return device
  }

  public groupControl(attributes)
  {
    this.loading = true;

    var devices: any[] = []
    for(let attr of attributes)
    {

      for( let d of this.group_devices)
      {
        var _d = this.updateDeviceAttr(attr, d);
        if (_d)
        {
          //console.log(_d);
          devices.push(_d);
        }
      }
    }

    for(let d of devices)
    {
      //update
      if(this.cfg.DEBUG)
      {
        console.log("Update: "+d.id)
      }
      this.aggregateService.updateAggregate(d, 'update', 'device', new Device());
    }
    this.saveDevicegroup(this.selected_devicegroup);

    this.loading = false;

  }

  public openDevice(device)
  {
    if(device.id)
    {
      this.open_device.emit(device)
    }
  }

}
