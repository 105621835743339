import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from './config';
import { of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';


export class BaseService 
{
  protected cfg: Config = new Config();
  protected endpoint: string = "";

  constructor(protected http: HttpClient, protected authService: any) { }

  // server error handler
  private _handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // ---- Requests---------------------------------------------------------------------------
  // GET
  // create request to get entities
  protected __request(endpoint: string, post_data, return_on_error): Observable<any>
  {
    return this.http.post(endpoint, post_data, this.cfg.HTTP_OPTIONS).pipe(
      // on error
      catchError(this._handleError(endpoint, return_on_error)))
  }

  // UPLOAD FILE
  protected __uploadFile(id: string, file: File):  Observable<any>
  {
    var post_data = {
        "auth_id": "",
        "auth_key": "",
        "id": id
      };

    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;

    // prepare fomr data
    var formData = new FormData();
    formData.append('file', file, file.name);   
    formData.append('id', post_data.id);
    formData.append('auth_id', post_data.auth_id);
    formData.append('auth_key', post_data.auth_key);

    return this.http.post(this.endpoint , formData).pipe(
      // on error
      catchError(this._handleError(this.endpoint, {})))
  }

  // Serve file
  // create request to get entity by id from projection
  protected __serveFile(id: string)
  {
    var post_data = {"auth_id": "", "auth_key": "", "id": ""};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    post_data.id = id;
    return this.__request(this.endpoint, post_data, {});
  }

  protected __deleteFile(id: string)
  {
    var post_data = {"auth_id": "", "auth_key": "", "id": ""};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    post_data.id = id;
    return this.__request(this.endpoint, post_data, {});
  }

  // QUERIES
  // get array of all entities from server
  protected __getAggregate()
  {
    var post_data = {"auth_id": "", "auth_key": ""};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    return this.__request(this.endpoint+"get", post_data, []);
  }

  // create request to get entity by id from aggregate
  protected __getByIdAggregate(id: string)
  {
    var post_data = {"auth_id": "", "auth_key": "", "id": ""};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    post_data.id = id;
    return this.__request(this.endpoint+"get", post_data, {});
  }
  
  //get array of custom projection

  protected __getCustomProjection(params: any, page: number)
  {
    var post_data = {"auth_id": "", "auth_key": "", "limit": 0, "page": 0};
    params.forEach(e => {
      post_data[e[0]] = e[1];
    });
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    if(page)
    {
      post_data.limit = this.cfg.PAGE_LIMIT;
      post_data.page = page;
    }
    return this.__request(this.endpoint, post_data, []);
  }
  // get array of all entities from server
  protected __getProjection(page: number)
  {
    var post_data = {"auth_id": "", "auth_key": "", "limit": 0, "page": 0};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    if(page)
    {
      post_data.limit = this.cfg.PAGE_LIMIT;
      post_data.page = page;
    }
    return this.__request(this.endpoint+"get/all", post_data, []);
  }

  // create request to get entity by id from projection
  protected __getByIdProjection(id: string)
  {
    var post_data = {"auth_id": "", "auth_key": "", "id": ""};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    post_data.id = id;
    return this.__request(this.endpoint+"get/by_id", post_data, {});
  }

  // get by id list
  protected __getAggregateList(_list: string[])
  {
    var post_data = {"auth_id": "", "auth_key": "", "id_list": []};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    post_data.id_list = _list;
    return this.__request(this.endpoint+"get/by_id_list/", post_data, []);
  }

  //find by keyword
  protected __find(keyword: string)
  {
    var post_data = {"auth_id": "", "auth_key": "", "find": ""};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    post_data.find = keyword;
    return this.__request(this.endpoint+"get/find", post_data, {});
  }

  //
  protected __search(keywords: string)
  {
    var post_data = {"auth_id": "", "auth_key": "", "keywords": ""};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    post_data.keywords = keywords;
    return this.__request(this.endpoint+"get/search", post_data, {});
  }

  // COMMANDS
  // CREATE/UPDATE
  protected __update<T>(type, aggregate: any, command: string, )
  {
    var post_data = {"auth_id": "", "auth_key": "", "id": "", "jdata": new type.constructor()};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    post_data.id = aggregate.id;
    post_data.jdata = aggregate;
    return this.__request(this.endpoint+command, post_data, {});
  }

  // DELETE
  protected __delete(id: string)
  {
    var post_data = {"auth_id": "", "auth_key": "", "id": ""};
    post_data.auth_id = this.authService.user.auth_id;
    post_data.auth_key = this.authService.user.auth_key;
    post_data.id = id;
    return this.__request(this.endpoint+"delete", post_data, {});
  }

  protected __loginExists(login: string)
  {
    var post_data = {"login": ""}
    post_data.login = login

    return this.__request(this.endpoint, post_data, false);
  }

  protected __emailExists(email: string)
  {
    var post_data = {"email": ""}
    post_data.email = email

    return this.__request(this.endpoint, post_data, false);
  }

  protected __forgottenPassword(email: string)
  {
    var post_data = {"email": email}

    return this.__request(this.endpoint, post_data, false);
  }

  protected __getAres(ico: string)
  {
    var post_data = {"ico": ""};
    post_data.ico = ico;

    return this.__request(this.endpoint, post_data, {})
  }

  protected __getLastUpdate(id: string)
  {
    var post_data = {"auth_id": this.authService.user.auth_id, "auth_key": this.authService.user.auth_key, "id": id};
    return this.__request(this.endpoint, post_data, {});
  }

  protected __registerUser(user: any)
  {
    var post_data: any;

    post_data = {"user": user}

    return this.__request(this.endpoint, post_data, {});
  }

  protected __exportShapshot(auth_id: string, auth_key: string, skip: string[], include_reference)
  {
    var post_data = {"auth_id": auth_id, "auth_key": auth_key, "skip": skip, "include_reference": include_reference};
    return this.__request(this.endpoint, post_data, {});
  }

  protected __importSnapshot(auth_id: string, auth_key: string, data: any)
  {
    var post_data = {"auth_id": auth_id, "auth_key": auth_key, "jdata": data};
    return this.__request(this.endpoint, post_data, {});
  }

  protected __setOutage(outage: any)
  {
    var post_data = {"auth_id": this.authService.user.auth_id, "auth_key": this.authService.user.auth_key, "outage": outage};
    return this.__request(this.endpoint, post_data, {});
  }

  protected __getHistory(id: string, _from: string, _to: string)
  {
    var post_data = {"auth_id": this.authService.user.auth_id, "auth_key": this.authService.user.auth_key, "id": id, "from": _from, "to": _to};
    return this.__request(this.endpoint, post_data, []);
  }


  // ---- Postdata Handlers ------------------------------------------------
  // assign data from id, jdata
  protected _assignFromJdata<T>(type, data): T
  {
    var entity = new type.constructor();
    entity = data.jdata;
    return entity;
  }

  // assign data from single entity
  protected _assignFromData<T>(type, data): T
  {
    var entity = new type.constructor();
    entity = data;
    return entity;
  }
}

