import { Component, Inject, OnInit } from '@angular/core';
import  {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../language.service';
import { Attribute, Schedule } from "../datatypes"
import { Tools } from '../tools';


// (JSON.parse(JSON.stringify(objectToCopy)));


@Component({
  selector: 'app-add-schedule',
  templateUrl: './add-schedule.component.html',
  styleUrls: ['./add-schedule.component.css']
})
export class AddScheduleComponent implements OnInit {
  public lang: any;
  public tools: Tools = new Tools();
  public attribute: Attribute = new Attribute("", "", "string", "");
  public msg: string = "";
  public attr_index = 0;

  public schedule: Schedule = new Schedule();
  public available_attrs: Attribute[] = [];
  public from = "00:00";
  public to = "00:00";

  public default: any = 0;

  constructor(
    public languageService: LanguageService,
    public dialogRef: MatDialogRef<AddScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  
  private attrExists(attr: Attribute, target: Schedule[]): boolean
  {
    for(let s of target)
    {
      if(attr.name == s.attr.name)
      {
        return true;
      }
    }

    return false;
  }

  ngOnInit()
  {
    this.lang = this.languageService.language_data;

    for(let a of this.data.attributes)
    {
      if(!this.attrExists(a, this.data.target))
      {
        if(a.name != 'auto' && a.type != 'string')
        {
          this.available_attrs.push((JSON.parse(JSON.stringify(a))))
        }
      }
    }

    if(!this.available_attrs.length)
    {
      this.msg = "not_available_attr"
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void
  {
    this.msg = "";

    if(this.schedule.steps < 2)
    {
      this.schedule.steps = 2;
    }

    this.schedule.from_h = Number(this.from.split(":")[0])
    this.schedule.from_m = Number(this.from.split(":")[1])

    this.schedule.to_h = Number(this.to.split(":")[0])
    this.schedule.to_m = Number(this.to.split(":")[1])

    // Time steps
    this.schedule.time_steps = this.tools.timeSteps(this.from, this.to, this.schedule.steps)

    for(let x of [].constructor(this.schedule.steps))
    {
      if(this.available_attrs[this.attr_index].type == 'boolean')
      {
        if (!this.default)
        {
          this.available_attrs[this.attr_index].value = false;
        }
        else
        {
          this.available_attrs[this.attr_index].value = true;
        }
      }
      else
      {
        this.available_attrs[this.attr_index].value = this.default;
      }
      
      this.schedule.attribute_steps.push((JSON.parse(JSON.stringify(this.available_attrs[this.attr_index]))));
    }

    this.schedule.attr = (JSON.parse(JSON.stringify(this.available_attrs[this.attr_index])));
    
    this.dialogRef.close(this.schedule);
  }
}

