import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { LanguageService } from '../language.service';
import { AggregateService } from '../aggregate.service';
import { User, Location } from '../datatypes';
import { v4 as uuid } from 'uuid';
import { Tools } from "../tools"
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { trigger, style, animate, transition } from '@angular/animations';
import { Config } from '../config'
import { SelectionModel } from '@angular/cdk/collections';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { AggregateAssignDialogComponent } from '../aggregate-assign-dialog/aggregate-assign-dialog.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ]
})

export class UserComponent implements OnInit {
  lang: any;
  level: string = 'user';
  users: User[] = []
  selected_user: User;
  action_msg: string = ""
  tools = new Tools();
  cfg = new Config();
  loading: boolean = true;

  tableColumns: string[] = ['reference', 'name', 'level', 'select']
  tableSource: any;
  tableSelection = new SelectionModel<User>(true, []);

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    public authService: AuthService,
    public languageService: LanguageService,
    public aggregateService: AggregateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.lang = this.languageService.language_data;
    this.level = this.authService.user.level;

    this.init()
  }

  public async init()
  {
    this.loading = true;

    if(this.level == 'user')
    {
      this.selected_user = await this.aggregateService.getAggregateById(this.authService.user.id, 'user', new User());
    }

    if(this.level == 'admin')
    {
      var users: User[] = await this.aggregateService.getAggregates('user', new User(), 0);
      this.users = []
      for(let u of users)
      {
        if(u.level == 'user')
        {
          if(!u.locations_id.length)
          {
            this.users.push(u);
          }
          else
          {
            for(let location_id of this.authService.user.locations_id)
            {
              if(u.locations_id.includes(location_id))
              {
                this.users.push(u);
                break;
              }
            }
          }
        }

        if(u.id == this.authService.user.id)
        {
          this.users.push(u);
        }
      }
    }

    if(this.level == 'root')
    {
      this.users = await this.aggregateService.getAggregates('user', new User(), 0);
    }

    this.updateTable()

    this.loading = false;
  }

  // update mat table data
  private updateTable()
  {
    this.tableSource = new MatTableDataSource(this.users);
    this.tableSource.sort = this.sort;
    this.tableSource.paginator = this.paginator;
    this.tableSelection = new SelectionModel<User>(true, []);
  }

  // table filter
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableSource.filter = filterValue.trim().toLowerCase();

    if (this.tableSource.paginator) {
      this.tableSource.paginator.firstPage();
    }
  }

  // table selection
  public isAllSelected() {
    const numSelected = this.tableSelection.selected.length;
    const numRows = this.tableSource.data.length;
    return numSelected === numRows;
  }

  // table selection
  public masterToggle() {
    this.isAllSelected() ?
        this.tableSelection.clear() :
        this.tableSource.data.forEach(row => this.tableSelection.select(row));
  }

  // update after delay
  public async _update(delay: number = 0)
  {
    await this.tools.delay(delay);
    this.init()
    if(this.selected_user)
    {
      this.selected_user = await this.aggregateService.getAggregateById(this.selected_user.id, 'user', new User());
    }
  }

  // Hanndle action from header
  public onAction(e)
  {
    this.loading = true;


    this.action_msg = "";
    if(e == "add")
    {
      if(this.authService.user.level != 'user')
      {
        if(!this.selected_user)
        {
          this.newUser();
        }
        
      }
      else
      {
        this.action_msg = "t_not_allowed";
      }
    }
    else if(e == "delete")
    {
      if(this.selected_user)
      {
        if(this.authService.user.id != this.selected_user.id)
      {
       this.confirmDelete();  
      }
      else
      {
        this.action_msg = "t_cannot_remove_self";
      }
      }
    }
    else if(e == "refresh")
    {
      this.action_msg = '';
      this._update();
    }

    this.loading = false;
  }

  // callback click on row
  public async select(row)
  {
    this.loading = true;
    if(row.id)
    {
      this.selected_user = await this.aggregateService.getAggregateById(row.id, 'user', new User())
    }

    this.loading = false;
  }

  // new user 
  public async newUser(locations: string[] = [])
  {
    this.loading = true;

    var user = new User();
    user.id = uuid();
    user.name = "Nový uživatel";
    user.locations_id = locations;
    user.level = "user";
    
    this.aggregateService.updateAggregate(user, 'create', 'user', new User());
    this.users.push(user);
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;
  }

  // user save 
  public saveUser(user: User)
  {
    this.loading = true;

    if(user.id)
    {
      user.name = user.first_name + " " + user.last_name; 
      this.aggregateService.updateAggregate(user, 'update', 'user', new User());
    }
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;
  }

  // delete user
  public deleteUser(id)
  {
    this.loading = true;

    this.aggregateService.deleteAggregate(id, 'user');

    var cnt = 0;
    this.users.forEach(e => {
      if(e.id == id)
      {
        this.users.splice(cnt, 1);
      }
      cnt += 1;
    });
    
    this.selected_user = null;
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;

  }

  public changePassword()
  {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: '400px',
      data: {aggregate: this.selected_user}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
      {
        // update password
        this.selected_user.password = result;
        this.saveUser(this.selected_user);
      }
    });
  }

  // confirm of delete
  public confirmDelete()
  {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {aggregate: this.selected_user, aggregate_name: 'user'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
      {
        this.deleteUser(this.selected_user.id);
      }
    });
  }

  public async manageLocations(user: User)
  {
    var source: any[] = [];
    var locations: Location[] = await this.aggregateService.getAggregates('location', new Location(), 0);
    var locations_dict = {}

    for(let l of locations)
    {
      if(!user.locations_id.includes(l.id))
      {
        if(this.level == 'admin')
        {
          if(this.authService.user.locations_id.includes(l.id))
          {
            source.push(l.id);
          }
        }
        else
        {
          source.push(l.id)
        }
      }
      locations_dict[l.id] = l;
    }

    const dialogRef = this.dialog.open(AggregateAssignDialogComponent, {
      width: '400px',
      data: {name: "locations", aggregates: locations_dict, source: source, target: user.locations_id}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        user.locations_id = result;
      }
    });
  }

}
