import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { trigger, style, animate, transition } from '@angular/animations';
import { LanguageService } from '../language.service';
import { AggregateService } from '../aggregate.service';
import { AuthService } from '../auth.service';
import { User } from '../datatypes';
import { v4 as uuid } from 'uuid';
import { Md5 } from 'ts-md5/dist/md5';
import { MapService } from '../map.service';
import { Config } from '../config';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ]
})

export class MainComponent implements OnInit {
  opened = true;
  target: string = 'dashboard'
  lang: any = null
  device_to_open: any = null;
  cfg = new Config();


  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(
    public languageService: LanguageService,
    public aggregateService: AggregateService,
    public authService: AuthService,
    public mapService: MapService
  )
  {}

  ngOnInit() {
    if (window.innerWidth < 768) {
      this.sidenav.fixedTopGap = 55;
      this.opened = false;
    } else {
      this.sidenav.fixedTopGap = 55;
      this.opened = true;
    }

    this.lang = this.languageService.language_data;
    this.mapService.fetch_position();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 768) {
      this.sidenav.fixedTopGap = 55;
      this.opened = false;
    } else {
      this.sidenav.fixedTopGap = 55
      this.opened = true;
    }
  }

  isBiggerScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 768) {
      return true;
    } else {
      return false;
    }
  }

  public selectTarget(t: string)
  {
    this.device_to_open = null;
    this.target = t;
    
  }

  // public createRoot()
  // {
  //   var md5 = new Md5();
  //   var user = new User();
  //   user.email = "george4291@gmail.com";
  //   user.password = md5.appendStr('scheuermann').end().toString();
  //   user.id = uuid();
  //   user.first_name = "Jiri";
  //   user.last_name = "Vopelka";
  //   user.level = "root"
  //   user.contact.city = "Litomerice";
  //   user.contact.post_code = "412 01";
  //   user.contact.street = "Liskova 2013/10";
  //   user.name = "Jirka Vopelka";

  //   this.aggregateService.updateAggregate(user, 'create', 'user', new User());
  // }

  public openDevice(e)
  {
    if(e.id)
    {
      this.device_to_open = e.id;
      this.target = "devices";
    }
  }


}
