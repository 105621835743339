export const CZ = {
    "device":                           "Zařízení",
    "devices":                          "Zařízení",
    "devicegroups":                     "Skupiny zařízení",
    "devicegroup":                      "Skupina zařízení",
    "location":                         "Lokalita",
    "locations":                        "Lokality",
    "user":                             "Uživatel",
    "users":                            "Uživatelé",
    "dashboard":                        "Nástěnka",
    "event":                            "Událost",
    "events":                           "Události",
    "email":                            "E-mail",
    "phone":                            "Telefon",
    "sms":                              "SMS",
    "password":                         "Heslo",
    "system_config":                    "Konfigurace systému",
    "id":                               "ID",
    "reference":                        "Reference",
    "language":                         "Jazyk",
    "userlevel":                        "Uživatelská úroveň",
    "level":                            "Úroveň",
    "firstname":                        "Jméno",
    "lastname":                         "Příjmení",
    "address":                          "Adresa",
    "street":                           "Ulice",
    "city":                             "Město / Obec",
    "post_code":                        "PSČ",
    "admin":                            "Administrátor",
    "root":                             "Root",
    "name":                             "Název",
    "filter":                           "Filtr",
    "new":                              "Nový",
    "add":                              "Přidat",
    "delete":                           "Smazat",
    "refresh":                          "Obnovit",
    "save":                             "Uložit",
    "save_close":                       "Uložit a zavřít",
    "close":                            "Zavřít",
    "previous":                         "Předchozí",
    "next":                             "další",
    "submit":                           "Odeslat",
    "cancel":                           "Zrušit",
    "ok":                               "OK",
    "select":                           "Vybrat",
    "upload":                           "Nahrát",
    "download":                         "Stáhnout",
    "find":                             "Hledat",
    "search":                           "Hledat",
    "change":                           "Změnit",
    "up":                               "Nahoru",
    "down":                             "Dolů",
    "open":                             "Otevřít",
    "yes":                              "Ano",
    "no":                               "Ne",
    "logout":                           "Odhlásit",
    "login":                            "Přihlásit",
    "change_password":                  "Změna hesla",
    "new_password":                     "Nové heslo",
    "new_password_again":               "Nové heslo znovu",
    "confirm_question":                 "Opravdu?",
    "GPS":                              "GPS",
    "lat":                              "zeměpisná šířka",
    "lon":                              "zeměpisná délka",
    "add_attribute":                    "Přidat atribut",
    "edit_attribute":                   "Upravit atribut",
    "delete_attribute":                 "Smazat atribut",
    "description":                      "Popis",
    "type":                             "Typ",
    "unit":                             "Jednotka",
    "string":                           "Text",
    "number":                           "Číslo",
    "boolean":                          "Bool",
    "read":                             "Čtení",
    "write":                            "Zápis",
    "default":                          "Výchozí hodnota",
    "value":                            "Hodnota",
    "select_attribute":                 "Vyberte atribut",
    "selected_attribute":               "Vybraný atribut",
    "manage":                           "Správa",
    "available":                        "K dispozici",
    "assigned":                         "Přiřazeno",
    "control":                          "Ovládání",
    "all":                              "Vše",
    "local_address":                    "Lokální adresa",
    "gateway":                          "Brána",
    "enabled":                          "Povoleno",
    "priority":                         "Priorita",
    "schedule":                         "Harmonogram",
    "schedules":                        "Harmonogramy",
    "add_schedule":                     "Přidej harmonogram",
    "attribute":                        "Atribut",
    "from":                             "Od",
    "to":                               "Do",
    "steps":                            "Úseků",
    "marker":                           "Označení",
    "device_count":                     "Počet zařízení",
    "map":                              "Mapa",
    "info":                             "Info",
    "server_url":                       "Adresa serveru",
    "server_status":                    "Stav serveru",
    "system_outage":                    "Odstávka systému",
    "export":                           "Export",
    "import":                           "Import",
    "confirm":                          "Potvrzení",
    "include_references":               "Včetně referencí",
    "file":                             "Soubor",
    "group_control":                    "Ovládání skupin",
    "select_group":                     "Vyber skupinu",
    "select_device":                    "Vyber zařízení",
    "online":                           "On-line",
    "sensors":                          "Senzory",
    "not_available_attr":               "Nelze zvolit atribut",
    "last_update":                      "Poslední aktualizace",
    "period":                           "Období",
    "history":                          "Historie",
    "choose_date":                      "Vyberte datum",
    "choose_time":                      "Vyberte čas",
    "missing_date":                     "Zadejte datum",
    "missing_time":                     "Zadejte čas",
    "date_from_older":                  "Datum 'DO' nemůže být starši než 'OD'",
    "time_from_older":                  "Čas 'DO' nemůže být starší než 'OD'",
    "no_data":                          "Žádná data k zobrazení",
    "condition":                        "Podmínka",

    "t_bad_login":                      "Nesprávný e-mail/heslo",
    "t_cannot_remove_self":             "Nelze smazat vlastní účet",
    "t_password_not_match":             "Hesla se neshodují",
    "t_password_empty":                 "Heslo je prázdné",
    "t_not_allowed":                    "Nepovoleno",
    "t_no_locations":                   "Žádné lokality k dispozici.",
    "t_no_devices":                     "Žádné zařízení k dispozici.",
    "t_no_devicegroup":                 "Žádné skupina zařízení k dispozici.",
    "t_no_notification":                "Žádné událost k dispozici.",
    "t_no_name":                        "Zadejte název"
    
}

export const EN = {
    "device":                           "Device",
    "devices":                          "Devices",
    "devicegroups":                     "Device groups",
    "devicegroup":                      "Device group",
    "location":                         "Location",
    "locations":                        "Locations",
    "user":                             "User",
    "users":                            "Users",
    "dashboard":                        "Dashboard",
    "event":                            "Event",
    "events":                           "Events",
    "email":                            "E-mail",
    "phone":                            "Phone",
    "sms":                              "SMS",
    "password":                         "Password",
    "system_config":                    "System config",
    "id":                               "ID",
    "reference":                        "Reference",
    "language":                         "Language",
    "userlevel":                        "User level",
    "level":                            "Level",
    "firstname":                        "First name",
    "lastname":                         "Last name",
    "address":                          "Address",
    "street":                           "Street",
    "city":                             "City",
    "post_code":                        "ZIP",
    "admin":                            "Admin",
    "root":                             "Root",
    "name":                             "Name",
    "filter":                           "Filter",
    "new":                              "New",
    "add":                              "Add",
    "delete":                           "Delete",
    "refresh":                          "Refresh",
    "save":                             "Save",
    "save_close":                       "Save and close",
    "close":                            "Close",
    "previous":                         "Previous",
    "next":                             "Next",
    "submit":                           "Submit",
    "cancel":                           "Cancel",
    "ok":                               "OK",
    "select":                           "Select",
    "upload":                           "Upload",
    "download":                         "Download",
    "find":                             "Find",
    "search":                           "Search",
    "change":                           "Change",
    "up":                               "Up",
    "down":                             "Down",
    "open":                             "Open",
    "yes":                              "Yes",
    "no":                               "No",  
    "logout":                           "Log out",
    "login":                            "Log in",
    "change_password":                  "Password change",
    "new_password":                     "New password",
    "new_password_again":               "new_password_again",
    "confirm_question":                 "Are you sure?",
    "GPS":                              "GPS",
    "lat":                              "Latitude",
    "lon":                              "Longitude",
    "add_attribute":                    "Add attribute",
    "edit_attribute":                   "Edit attribute",
    "delete_attribute":                 "Delete attribute",
    "description":                      "Description",
    "type":                             "Type",
    "unit":                             "Unit",
    "string":                           "Text",
    "number":                           "Number",
    "boolean":                          "Bool",
    "read":                             "Read",
    "write":                            "write",
    "default":                          "Default value",
    "value":                            "Value",
    "select_attribute":                 "Select attribute",
    "selected_attribute":               "Selected attribute",
    "manage":                           "Manage",
    "available":                        "Available",
    "assigned":                         "Assigned",
    "control":                          "Control",
    "all":                              "All",
    "local_address":                    "Local address",
    "gateway":                          "Gateway",
    "enabled":                          "Enabled",
    "priority":                         "Priority",
    "schedule":                         "Schedule",
    "schedules":                        "Schedules",
    "add_schedule":                     "Add schedule",
    "attribute":                        "Attribute",
    "from":                             "From",
    "to":                               "To",
    "steps":                            "Steps",
    "marker":                           "Marker",
    "device_count":                     "Device count",
    "map":                              "Map",
    "info":                             "Info",
    "server_url":                       "Server url",
    "server_status":                    "Server status",
    "system_outage":                    "System outage",
    "export":                           "Export",
    "import":                           "Import",
    "confirm":                          "Confirm",
    "include_references":               "Include references",
    "file":                             "File",
    "group_control":                    "Group control",
    "select_group":                     "Select group",
    "select_device":                    "Select device",
    "online":                           "On-line",
    "sensors":                          "Sensors",
    "not_available_attr":               "No available attribute",
    "last_update":                      "Last update",
    "period":                           "Period",
    "history":                          "History",
    "choose_date":                      "Choose date",
    "choose_time":                      "Choose time",
    "missing_date":                     "Missing date",
    "missing_time":                     "Missing time",
    "date_from_older":                  "Date 'TO' can not be older than 'FROM'",
    "time_from_older":                  "Time 'TO' can not be older than 'FROM'",
    "no_data":                          "No data",
    "condition":                        "Condition",

    "t_bad_login":                      "Bad email/password",
    "t_cannot_remove_self":             "Can not delete your account",
    "t_password_not_match":             "Passwords do not match",
    "t_password_empty":                 "Password is empty",
    "t_not_allowed":                    "Not allowed",
    "t_no_locations":                   "No locations",
    "t_no_devices":                     "No devices",
    "t_no_devicegroup":                 "No devicegroup",
    "t_no_notification":                "No notification",
    "t_no_name":                        "Name not set"
     
}
