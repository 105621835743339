import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { User } from './datatypes';
import { Md5 } from 'ts-md5/dist/md5';
import { CookieService } from 'ngx-cookie-service'
import { LanguageService } from './language.service';
import { UserService } from './user.service';
import { Config } from './config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  cfg = new Config();
  public user = new User();
  public hidden_aggregates: string[] = [];

  constructor(private userService: UserService, private cookieService: CookieService, private languageService: LanguageService) {
   }

  public logOut()
  {
    this.user = new User();
    this.cookieService.set('user_email', '', 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax");
    this.cookieService.set('user_id', '', 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax");
    this.cookieService.set('user_hash', '', 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax");
    this.cookieService.set('selected_location', '', 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax")
  }

  public checkCookies()
  {
    var user = new User()
    user.email = this.cookieService.get('user_email');
    user.id = this.cookieService.get('user_id');
    user.password = this.cookieService.get('user_hash');

    // login from cookies
    this._login(user.email, user.password);

  }

  private async _login(email: string, hash: string): Promise<User>
  {
    var user = new User();

    user = await this.userService.getUserByEmail(email, hash);
    if(user.language)
    {
      this.languageService.set_lang(user.language);
    }
    if(user.id)
    {
      if(user.password == hash)
      {
        this.user = user;

        this.cookieService.set('user_email', this.user.email, 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax");
        this.cookieService.set('user_id', this.user.id, 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax");
        this.cookieService.set('user_hash', hash, 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax");

        // this.cookieService.set('user_email', this.user.email);
        // this.cookieService.set('user_id', this.user.id);
        // this.cookieService.set('user_hash', hash);
        return user;
      }
      else
      {
        this.user = new User();
        this.cookieService.set('user_email', '', 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax");
        this.cookieService.set('user_id', '', 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax");
        this.cookieService.set('user_hash', '', 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax");
        this.cookieService.set('selected_location', '', 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax")
      }
      return this.user;
    }
    return new User();
  }

  public async logIn(email: string, pass: string): Promise<User>
  {
    var user = new User();
    var md5 = new Md5();
    var password = md5.appendStr(pass).end().toString();
    user = await this._login(email, password);

    return user
    
  }

  public getUser(): User
  {
    return this.user;
  }
  
}