import { Component, OnInit } from '@angular/core';
import { AuthService } from "../auth.service"
import { User } from "../datatypes";
import { LanguageService } from '../language.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ]
})

export class UserLoginComponent implements OnInit {
  public msg: string = "";
  public show: boolean= true;
  public lang: any;

  constructor(private authService: AuthService, public languageService: LanguageService) { }

  ngOnInit() { 
    this.lang = this.languageService.language_data;
   }

  public async logIn(email: string, password: string)
  {
    var user = new User();
    user =  await this.authService.logIn(email, password);
    if(!user.id)
    {
      this.msg = "t_bad_login";
    }

  }

}
