export class Health
{
  constructor(public state: string = "")
  {}
  
  version: string = "";
  uptime: string = "";
  events: number = 0;
  outage = {date: "", time: ""};

}

export class Base{
    id: string = "";
    name: string = "";
    reference: string = "";
    description: string = "";
    enabled: boolean = true;
}

export class User extends Base
{
    email: string = "";
    password: string = "";
    first_name: string = "";
    last_name: string = "";
    phone: string = "";
    contact: Address = new Address();
    level: string = "user";
    login: boolean = true;
    locations_id: string[] = [];
    language: string = "CZ";
    map_type: string = "roadmap"

    auth_id: string = "";
    auth_key: string = "";
}

export class Location extends Base
{
    gps: Gps = new Gps();
    contact: Address = new Address();
    primary_user_id: string = "";
    read: Attribute[] = [];
    write: Attribute[] = [];
    
}


export class Address
{
    street: string = "";
    city: string = "";
    post_code: string = "";
}

export class Device extends Base
{
    type: number = 0;
    marker: string = "";
    local_address: number = 0;
    gateway_address: number = 0;
    gps: Gps = new Gps();
    location: string = "";
    groups: string[] = [];
    read: Attribute[] = [];
    write: Attribute[] = [];
}

export class Schedule 
{
    from_h: number = 0;
    from_m: number = 0;
    to_h: number = 0;
    to_m: number = 0;
    steps: number = 2;
    time_steps: string[] = [];
    attribute_steps: Attribute[] = [];
    attr: Attribute = new Attribute('test', 'Test', 'number', 0, '%', 0, 100);
}

export class Devicegroup extends Base
{
    location: string = "";
    priority: Number = 1;
    schedules:  Schedule[] = [];
}

export class Notification extends Base
{
    location: string = "";
    priority: Number = 1;
    author_id: string = "";
    users:  string[] = [];
    aggregate_type = "device";
    aggregate_id: string = "";
    attribute: Attribute = null;
    condition: string = "equal";
    value: any = "";
    email: boolean = false;
    sms: boolean = false;
}

export class Gps
{
    lat: number = 0.0;
    lon: number = 0.0;
    description: string = "";
}

export class Attribute
{
    public name: string;
    public desc: string;
    public type: string;
    public value: any;
    public unit: string;
    public min: number;
    public max: number;
    public default: any;
    
    constructor(name: string, desc: string, type: string, value: any, unit: string = '', min: number = 0, max: number = 0)
    {
        this.name = name;
        this.type = type;
        this.value = value;
        this.unit = unit;
        this.desc = desc;
        this.min = min;
        this.max = max;
    }
}