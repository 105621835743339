import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import 'chartjs-plugin-dragdata/dist/chartjs-plugin-dragdata.js'

@Component({
  selector: 'app-datachart',
  templateUrl: './datachart.component.html',
  styleUrls: ['./datachart.component.css']
})
export class DatachartComponent implements OnInit {
  chartLabels: Array<any> = [];
  chartDatasets: Array<any> = [{ data: [], label: '' }];
  chartOptions: any = {};
  chartType: string = 'line';
  chartColors: Array<any> = []

  @Input()
  data: any = null;

  constructor() {}

  ngOnInit()
  {

    //console.log(this.schedule)

    this.chartDatasets[0].label = this.data.name;

    this.chartLabels = this.data.timeline;

    this.chartColors = [{backgroundColor: 'rgba(14, 90, 164, .3)', borderColor: 'rgba(14, 90, 164, .8)', borderWidth: 1,}];
    
    var min: number = 0;
    var max: number = 0;
    var first: boolean = true

    for(let a of this.data.data)
    {
      this.chartDatasets[0].data.push(a)

      if(first)
      {
        max = a;
        min = a;
        first = false;
      }
      else
      {
        if(a > max)
        {
          max = a
        }

        if (a < min)
        {
          min = a
        }
      }

    }

    min = min * 0.95;
    max = max * 1.05;


    this.chartOptions = {
      responsive: true,
      dragData: false,
      dragX: false,
      plugins: {
        datalabels: {
            display: false,
        },
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: false,
            min: min,
            max: max
          }
        }],
        xAxes: [{
          ticks: {
            autoSkip: true,
            maxTicksLimit:20
          }
        }]
      }
    };
  }    

}
