import { Injectable } from '@angular/core';
import { Health } from './datatypes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from './config';
import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class HealthService {
  private cfg = new Config();
  private endpoint = this.cfg.API_GATEWAY+'health/';
  public health = new Health();

  
 
  constructor(private http: HttpClient)
  {}

  
  // get data from server
  private getHealth(): Observable<Health>
  {
    var error_health = new Health();
    error_health.state = "Not responding";
    
    return this.http.get<Health>(this.endpoint).pipe(
      // on error
      catchError(this.handleError<Health>('getHealth', new Health("not responding")))); 
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public checkHealth(): void
  {
    this.getHealth().subscribe((data: Health) => {
      this.health = data
      if (this.cfg.DEBUG)
      {
        console.log(this.health);
      }
    });
  }
}