import { Component, Inject, OnInit } from '@angular/core';
import  {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from '../language.service';
import { Attribute } from "../datatypes"

@Component({
  selector: 'app-edit-attribute-dialog',
  templateUrl: './edit-attribute-dialog.component.html',
  styleUrls: ['./edit-attribute-dialog.component.css']
})
export class EditAttributeDialogComponent implements OnInit {
  public lang: any;
  public attribute: Attribute = null;
  public origin_attribute: Attribute = null;
  public msg: string = "";

  constructor(
    public languageService: LanguageService,
    public dialogRef: MatDialogRef<EditAttributeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit()
  {
    this.lang = this.languageService.language_data;
    this.origin_attribute = this.data.attribute
    this.attribute = JSON.parse(JSON.stringify(this.origin_attribute));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void
  {
    this.msg = "";
    this.origin_attribute.value = this.attribute.value;
    this.origin_attribute.unit = this.attribute.unit;
    this.origin_attribute.min = this.attribute.min;
    this.origin_attribute.max = this.attribute.max;
    this.origin_attribute.desc = this.attribute.desc;
    this.dialogRef.close()
  }
}
