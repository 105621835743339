import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Location, Device, Attribute } from '../datatypes';
import { Tools } from '../tools';
import { trigger, style, animate, transition } from '@angular/animations';
import { Config } from '../config'
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { AuthService } from '../auth.service';
import { LanguageService } from '../language.service';
import { AggregateService } from '../aggregate.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { v4 as uuid } from 'uuid';
import { MapService } from '../map.service';
import { AddAttributeDialogComponent } from '../add-attribute-dialog/add-attribute-dialog.component';
import { DeleteAttributeDialogComponent } from '../delete-attribute-dialog/delete-attribute-dialog.component';


@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ]
})

export class LocationComponent implements OnInit {
  lang: any;
  level: string = 'user';
  locations: Location[] = []
  location_devices: Device[] = []
  selected_location: Location;
  action_msg: string = ""
  tools = new Tools();
  cfg = new Config();
  loading: boolean = true;
  show_sensors: boolean = false;

  tableColumns: string[] = ['reference', 'name', 'select']
  tableSource: any;
  tableSelection = new SelectionModel<Location>(true, []);

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Output("open_device")
  public open_device: EventEmitter<Device> = new EventEmitter<Device>();

  constructor(
    public authService: AuthService,
    public languageService: LanguageService,
    public aggregateService: AggregateService,
    public dialog: MatDialog,
    public mapService: MapService
  ) { }

  ngOnInit() {
    this.lang = this.languageService.language_data;
    this.level = this.authService.user.level;

    this.init()
  }

  public async init()
  {
    this.loading = true;

    if(this.level == 'root')
    {
      this.locations = await this.aggregateService.getAggregates('location', new Location(), 0);
    }
    else
    {
      this.locations = []
      for(let id of this.authService.user.locations_id)
      {
        var location = await this.aggregateService.getAggregateById(id, 'location', new Location());
        this.locations.push(location);
      }
    }
    
    this.updateTable()

    this.loading = false;
  }

  // update mat table data
  private updateTable()
  {
    this.tableSource = new MatTableDataSource(this.locations);
    this.tableSource.sort = this.sort;
    this.tableSource.paginator = this.paginator;
    this.tableSelection = new SelectionModel<Location>(true, []);
  }

  // table filter
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableSource.filter = filterValue.trim().toLowerCase();

    if (this.tableSource.paginator) {
      this.tableSource.paginator.firstPage();
    }
  }

  // table selection
  public isAllSelected() {
    const numSelected = this.tableSelection.selected.length;
    const numRows = this.tableSource.data.length;
    return numSelected === numRows;
  }

  // table selection
  public masterToggle() {
    this.isAllSelected() ?
        this.tableSelection.clear() :
        this.tableSource.data.forEach(row => this.tableSelection.select(row));
  }

  // update after delay
  public async _update(delay: number = 0)
  {
    await this.tools.delay(delay);
    this.init()
    if(this.selected_location)
    {
      this.selected_location = await this.aggregateService.getAggregateById(this.selected_location.id, 'location', new Location());
      this.location_devices = await this.tools.devicesFromLocation(this.aggregateService, this.selected_location.id)
    }
  }

  // Hanndle action from header
  public onAction(e)
  {
    this.loading = true;

    this.action_msg = "";
    if(e == "add")
    {
      if(this.level == 'root')
      {
        if(!this.selected_location)
        {
          this.newLocation();
        }
      }
      else
      {
        this.action_msg = "t_not_allowed";
      }
    }
    else if(e == "delete")
    {
      if(this.selected_location)
      {
        if(this.level == "root")
        {
          this.confirmDelete();
        }
        else
        {
          this.action_msg = "t_not_allowed";
        }
      }
    }
    else if(e == "refresh")
    {
      this.action_msg = '';
      this._update();
    }

    this.loading = false;
  }

  // callback click on row
  public async select(row)
  {
    this.loading = true;

    if(row.id)
    {
      this.selected_location = await this.aggregateService.getAggregateById(row.id, 'location', new Location())
      this.location_devices = await this.tools.devicesFromLocation(this.aggregateService, this.selected_location.id)
    }

    this.loading = false;
  }

  // new location 
  public async newLocation()
  {
    this.loading = true;

    var location = new Location();
    location.id = uuid();
    location.name = "Nová lokalita";
    location.gps.lat = this.mapService.position[0];
    location.gps.lon = this.mapService.position[1];

    // Attributes
    location.read.push(new Attribute('version', 'Verze zařízení (GW)', 'string', ''));
    location.read.push(new Attribute('datetime', 'Datum a čas zařízení', 'string', ''));
    location.read.push(new Attribute('light', 'Soumrakový spínač', 'boolean', false));
    location.read.push(new Attribute('online', 'On-line', 'boolean', false));
    location.write.push(new Attribute('reboot', 'Restart GW', 'boolean', false));
    
    this.aggregateService.updateAggregate(location, 'create', 'location', new Location());
    this.locations.push(location);
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;
  }

  // location save 
  public saveLocation(location: Location)
  {
    this.loading = true;

    if(location.id)
    {
      this.aggregateService.updateAggregate(location, 'update', 'location', new Location());
    }
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;
  }

  // delete location
  public deleteLocation(id)
  {
    this.loading = true;

    this.aggregateService.deleteAggregate(id, 'location');

    var cnt = 0;
    this.locations.forEach(e => {
      if(e.id == id)
      {
        this.locations.splice(cnt, 1);
      }
      cnt += 1;
    });
    
    this.selected_location = null;
    this.location_devices = []
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;

  }

  // confirm of delete
  public confirmDelete()
  {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {aggregate: this.selected_location, aggregate_name: 'location'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
      {
        this.deleteLocation(this.selected_location.id);
      }
    });
  }

  // marker moved, set new GPS
  public mapMarkerMoved(e)
  {
    if(e.coords)
    {
      this.selected_location.gps.lat = e.coords.lat;
      this.selected_location.gps.lon = e.coords.lng;
    }
  }

  public addAttribute()
  {
    const dialogRef = this.dialog.open(AddAttributeDialogComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result.read)
        {
          this.selected_location.read.push(result.attribute)
        }
        else
        {
          this.selected_location.write.push(result.attribute)
        }
      }
    })
  }

  public deleteAttribute()
  {
    const dialogRef = this.dialog.open(DeleteAttributeDialogComponent, {
      width: '400px',
      data: {aggregate: this.selected_location}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result.read)
        {
          this.selected_location.read.splice(result.cnt, 1);
        }
        else
        {
          this.selected_location.write.splice(result.cnt, 1);
        }
      }
    })
  }

  public openDevice(device)
  {
    if(device.id)
    {
      this.open_device.emit(device)
    }
  }

  public getMsg(e)
  {
    this.action_msg = e;
    
  }

  public getLoading(e)
  {
    this.loading = e;
  }

}
