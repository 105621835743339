import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import './datatypes';
import { BaseService } from './base.service';
import { AuthService } from './auth.service';
import { User } from './datatypes';

@Injectable({
  providedIn: 'root'
})

export class AggregateService extends BaseService
{
  public open_aggregate: string = '';
  public open_id: string = '';
  public open_view: string = '';

  public user: any;

  constructor(protected http: HttpClient, public authService: AuthService) {
    super(http, authService);
  }

  // ---- Responses Handling ----------------------------------------------------------
  // get server response from aggregate
  private async _getAggregateByIdAggregate(id: string, class_prototype: any): Promise<boolean>
  {
    var data = await this.__getByIdAggregate(id).toPromise();
    var ret;
    try
    {
      ret = this._assignFromData<any>(class_prototype, data as any);
    }
    catch(e)
    {
      //console.log("No data from server");
    }

    return ret;
    //return Promise.resolve(true);
  }

  // Get server response from projection
  private async _getAggregateByIdProjection(id: string, class_prototype: any): Promise<any>
  {
    var data = await this.__getByIdProjection(id).toPromise();
    var ret;
    try
    {
      // console.log(data);
      ret = this._assignFromData<any>(class_prototype, data as any);
    }
    catch(e)
    {
      //console.log("No data from server");
    }
    return ret
    //return Promise.resolve(true);
  }

  // Fetch all entities from server
  public async _getAggregatesFromAggregate(class_prototype: any): Promise<any>
  {

    var data = await this.__getAggregate().toPromise().then();
    var ret = [];

    (data as any).forEach(
      (i) => {
        try
        {
          var result = this._assignFromJdata<any>(class_prototype, i);
          //add to array
          ret.push(result);
        }
        catch(e) {
          if (this.cfg.DEBUG)
          {
            //console.log("Server error");
          }
        }
        
    });
    return ret
    //return Promise.resolve(true);
  }

  // Fetch custom entites from server
  public async _getCustomProjection(params: any, class_prototype: any, page: number): Promise<any>
  {
    var data = await this.__getCustomProjection(params, page).toPromise().then();
    var ret = [];
    (data as any).forEach(
      (i) => {
        try
        {
          var result = this._assignFromData<any>(class_prototype, i);
          //add to array
          ret.push(result);
        }
        catch(e) {
          if (this.cfg.DEBUG)
          {
            console.log("Server error");
          }
        }
        
    });
    return ret
  }

  // Fetch all entities from server
  public async _getAggregatesFromProjection(class_prototype: any, page: number): Promise<any>
  {

    var data = await this.__getProjection(page).toPromise().then();
    var ret = [];
    (data as any).forEach(
      (i) => {
        try
        {
          var result = this._assignFromData<any>(class_prototype, i);
          //add to array
          ret.push(result);
        }
        catch(e) {
          if (this.cfg.DEBUG)
          {
            console.log("Server error");
          }
        }
        
    });
    return ret
    //return Promise.resolve(true);
  }
  // Fetch all entities from server
  public async _getAggregatesList(_list: string[], class_prototype: any): Promise<any>
  {

    var data = await this.__getAggregateList(_list).toPromise().then();
    var ret = [];
    try
    {
      (data as any).forEach(
        (i) => {
          try
          {
            var result = this._assignFromData<any>(class_prototype, i);
            //add to array
            ret.push(result);
          }
          catch(e) {
            if (this.cfg.DEBUG)
            {
              //console.log("Server error");
            }
          }
          
      });
    }
    catch(e)
    {

    }
    return ret
    //return Promise.resolve(true);
  }

  // Fetch find result from server
  public async _getFindResult(keyword: string, class_prototype: string): Promise<any>
  {
    var data = await this.__find(keyword).toPromise().then();
     var search_result = [];
    try
    {
      (data as any).forEach(
        (i) => {
          try
          {
            var result = this._assignFromData<any>(class_prototype, i);
            //add to array
             search_result.push(result);
          }
          catch(e)
          {
            if (this.cfg.DEBUG)
            {
              console.log("Server error");
            }
          }
          
      });
    }
    catch(e)
    {
      if (this.cfg.DEBUG)
      {
        console.log("Server error");
      }
    }
    return search_result;
    //return Promise.resolve(true);
  }

  public async _getSearchResult(keywords: string): Promise<string[]>
  {
    var data = await this.__search(keywords).toPromise().then();
    var search_result = [];
    try {
      (data as string[]).forEach(
        (i) => {
          try {
            search_result.push(i);
          }
          catch(e)
          {
            if (this.cfg.DEBUG)
            {
              console.log("Server error");
            }
          }
        }
      )
    }
    catch(e) {
      if (this.cfg.DEBUG)
      {
        console.log("Server error");
      }
    }

    return search_result;
  }


  // create/update
  private _updateAggregate(aggregate: any, command: string, class_prototype: any)
  {
    this.__update<any>(class_prototype, aggregate, command).subscribe();
  }

  //Delete
  private _deleteAggregate(id: string)
  {
    this.__delete(id).subscribe();
  }

  // Get server response from projection
  private async _uploadFile(id: string, file: File): Promise<string>
  {
    var data = await this.__uploadFile(id, file).toPromise();
    try
    {
      //console.log(data);
      if(data.id)
      {
        //console.log("UPLOADEDDDD");
        this.endpoint = this.cfg.FILE_SERVER+'file'+'/serve';
        var url: string = await this.__serveFile(data.id).toPromise();
        if(url.substr(0,4) == 'http')
        {
          return url
        }
        
      }
    }
    catch(e)
    {
      console.log("No data from server");
    }
    return '';
    //return Promise.resolve(true);
  }

  private async _deletefile(id: string): Promise<boolean>
  {
    var data = await this.__deleteFile(id).toPromise();

    try{
      //console.log(data);
      return true
    }
    catch(e){
      console.log("Server error");
    }

    return false;
  }

  // ---- Public functions --------------------------------------------------
  // get custom projection
  public async getCustomProjection(custom_endpoint: string, custom_params: any, sys_name: string, class_prototype: any, page: number): Promise<[]>
  {
    this.endpoint = this.cfg.API_GATEWAY+custom_endpoint+'/';
    return await this._getCustomProjection(custom_params, class_prototype, page);
  }
  // get list of entities
  public async getAggregates(sys_name: string, class_prototype: any, page: number, from: string = "projection", ): Promise<[]>
  {
    this.endpoint = this.cfg.API_GATEWAY+sys_name+'/';
    if(from == "aggregate")
    {
      return await this._getAggregatesFromAggregate(class_prototype);
    }
    else if(from == "projection")
    {
      return await this._getAggregatesFromProjection(class_prototype, page);
    }
  }

  public async getAggregatesList(_list: string[], sys_name: string, class_prototype: any): Promise<[]>
  {
    this.endpoint = this.cfg.API_GATEWAY+sys_name+'/';
    return await this._getAggregatesList(_list, class_prototype);
  }

  // find entities
  public async find(keyword: string, sys_name: string, class_prototype: any): Promise<[]>
  {
      this.endpoint = this.cfg.API_GATEWAY+sys_name+'/';
      return await this._getFindResult(keyword, class_prototype);

  }

  public async search(keywords: string, sys_name: string): Promise<string[]>
  {
    this.endpoint = this.cfg.API_GATEWAY+sys_name+'/';
    return await this._getSearchResult(keywords);
  }


  public async getAggregateById(id: string, sys_name: string, class_prototype: any, from: string = 'projection',): Promise<any>
  {
    this.endpoint = this.cfg.API_GATEWAY+sys_name+'/';
    var aggregate;
    if(from == 'projection')
    {
      aggregate = await this._getAggregateByIdProjection(id, class_prototype);
    }
    else
    {
      aggregate = await this._getAggregateByIdAggregate(id, class_prototype);
    }
    
    return aggregate;
  }

  public async getLastUpdate(id: string)
  {
    this.endpoint = this.cfg.API_GATEWAY+'device/get/get_last_update'
    return await this.__getLastUpdate(id).toPromise();
  }

  // create/update
  public updateAggregate(aggregate: any, command: string, sys_name: string, class_prototype: any)
  {
    this.endpoint = this.cfg.API_GATEWAY+sys_name+'/';
    this._updateAggregate(aggregate, command, class_prototype);
  }

  // delete
  public deleteAggregate(id: string, sys_name: string)
  {
    this.endpoint = this.cfg.API_GATEWAY+sys_name+'/';
    this._deleteAggregate(id);
  }

  public async uploadAndServeFile(id: string, file: File): Promise<string>
  {
    this.endpoint = this.cfg.FILE_SERVER+'file'+'/save';
    return await this._uploadFile(id, file);
  }

  public async deleteFile(id: string): Promise<boolean>
  {
    this.endpoint = this.cfg.FILE_SERVER+'file'+'/delete';
    return await this._deletefile(id);
  }

  public async loginExists(login: string): Promise<boolean>
  {
    this.endpoint = this.cfg.API_GATEWAY+"user/login_exists";

    return await this.__loginExists(login).toPromise();;
  }

  public async emailExists(login: string): Promise<boolean>
  {
    this.endpoint = this.cfg.API_GATEWAY+"kgateperson/email_exists";

    return await this.__emailExists(login).toPromise();
  }

  public async forgottenPassword(email: string): Promise<any>
  {
    this.endpoint = this.cfg.API_GATEWAY+"user/__forgottenpassword";

    return await this.__forgottenPassword(email).toPromise();
  }

  public async getAres(ico: string): Promise<any>
  {
    this.endpoint = this.cfg.API_GATEWAY+"tools/ares/get";

    return await this.__getAres(ico).toPromise();
  }

  public registerUser(user: any)
  {
    this.endpoint = this.cfg.API_GATEWAY+"user/__register";
    this.__registerUser(user).subscribe();  
  }

  // --- Import/export

  public async exportSnapshot(auth_id: string, auth_key: string, skip: string[], include_reference: boolean = false): Promise<any>
  {
    this.endpoint = this.cfg.API_GATEWAY+"tools/snapshot/export";

    if(auth_id && auth_key)
    {
      return this.__exportShapshot(auth_id, auth_key, skip, include_reference).toPromise();
    }

    return false;
  }

  public async importSnapshot(auth_id: string, auth_key: string, data: any): Promise<any>
  {
    this.endpoint = this.cfg.API_GATEWAY+"tools/snapshot/import";

    if(auth_id && auth_key)
    {
      return this.__importSnapshot(auth_id, auth_key, data).toPromise();
    }

    return false;
  }

  public async setOutage(outage: any): Promise<any>
  {
    this.endpoint = this.cfg.API_GATEWAY+"tools/outage";
    return this.__setOutage(outage).toPromise();
  }

  public async getDeviceHistory(id: string, _from: string, _to: string): Promise<any>
  {
    this.endpoint = this.cfg.API_GATEWAY+"device/get/history";
    return await this.__getHistory(id, _from, _to).toPromise();
  }

  public async getLocationHistory(id: string, _from: string, _to: string): Promise<any>
  {
    this.endpoint = this.cfg.API_GATEWAY+"location/get/history";
    return await this.__getHistory(id, _from, _to).toPromise();
  }

}