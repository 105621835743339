import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LanguageService } from '../language.service';
import { Attribute } from '../datatypes';

@Component({
  selector: 'app-attribute-write',
  templateUrl: './attribute-write.component.html',
  styleUrls: ['./attribute-write.component.css']
})
export class AttributeWriteComponent implements OnInit {
  public lang: any;

  @Input()
  public attribute: Attribute;
  @Input()
  public editable: Boolean = false;

  @Output('editAttribute')
  editAttribute: EventEmitter<Attribute> = new EventEmitter<Attribute>();

  constructor(
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.lang = this.languageService.language_data;
  }

  public edit(attr)
  {
    this.editAttribute.emit(attr);
  }
}