import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Device, Location, Notification, Attribute, Schedule, Devicegroup, User } from '../datatypes';
import { Tools } from '../tools';
import { trigger, style, animate, transition } from '@angular/animations';
import { Config } from '../config'
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { AuthService } from '../auth.service';
import { LanguageService } from '../language.service';
import { AggregateService } from '../aggregate.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { v4 as uuid } from 'uuid';
import { MapService } from '../map.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SelectAggregateDialogComponent } from '../select-aggregate-dialog/select-aggregate-dialog.component';
import { AggregateAssignDialogComponent } from '../aggregate-assign-dialog/aggregate-assign-dialog.component';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'cs-CZ'}
  ]
})

export class EventComponent implements OnInit {
  lang: any;
  level: string = 'user';
  notifications: Notification[] = []
  notification_type: number = 0;
  group_devices: Device[];
  locations: Location[] = [];
  all_locations: Location[] = [];
  locations_dict = {};
  selected_notification: Notification;
  selected_location: Location;
  selected_aggregate: any;
  selected_aggregate_attributes: any[] = [];
  action_msg: string = ""
  tools = new Tools();
  cfg = new Config();
  loading: boolean = true;
  tab_index: number = 0;

  all_attributes: Attribute[] = [];

  locations_loaded: boolean = false;

  tableColumns: string[] = ['reference', 'name', 'priority', 'select']
  tableSource: any;
  tableSelection = new SelectionModel<Notification>(true, []);

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Output("open_device")
  public open_device: EventEmitter<Device> = new EventEmitter<Device>();
  

  constructor(
    public authService: AuthService,
    public languageService: LanguageService,
    public aggregateService: AggregateService,
    public dialog: MatDialog,
    public mapService: MapService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.lang = this.languageService.language_data;
    this.level = this.authService.user.level;

    this.init()
  }

  public async init(update: boolean = false)
  {
    this.loading = true;

    if(!update)
    {
      this.locations_loaded = false;
    }

    this.all_locations = await this.aggregateService.getAggregates('location', new Location(), 0);
    for(let l of this.all_locations)
    {
      this.locations_dict[l.id] = l;
    }

    if(this.level == 'root')
    {
      this.locations = this.all_locations;
    }
    else
    {      
      this.locations = [];
      for(let l of this.all_locations)
      {
        if(this.authService.user.locations_id.includes(l.id))
        {
          this.locations.push(l);
        }
      }

    }

    if(!update)
    {
      var id = this.cookieService.get('selected_location');
      this.selected_location = null
      
      if(id)
      {
        this.tab_index = 0

        for(let l of this.locations)
        {
          if(l.id == id)
          {
            this.selected_location = l;
            break;
          }

          this.tab_index++;
          
        }
        if(!this.selected_location)
        {
          this.selected_location = this.locations[0];
        }
      }
      else
      {
        this.selected_location = this.locations[0];
      }
    }

    var notifications: Notification[] = await this.aggregateService.getAggregates('notification', new Notification(), 0);

    this.notifications = []
    if(this.selected_location)
    {
      for(let d of notifications)
      {
        if (d.location == this.selected_location.id)
        {
          this.notifications.push(d);
        }
      }
    }
    else
    {
      this.notifications = notifications;
    }

    if(!update)
    {
      this.locations_loaded = true;
    }

    
    
    this.updateTable()

    this.loading = false;
  }

  // update mat table data
  private updateTable()
  {
    this.tableSource = new MatTableDataSource(this.notifications);
    this.tableSource.sort = this.sort;
    this.tableSource.paginator = this.paginator;
    this.tableSelection = new SelectionModel<Notification>(true, []);
  }

  // table filter
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableSource.filter = filterValue.trim().toLowerCase();

    if (this.tableSource.paginator) {
      this.tableSource.paginator.firstPage();
    }
  }

  // table selection
  public isAllSelected() {
    const numSelected = this.tableSelection.selected.length;
    const numRows = this.tableSource.data.length;
    return numSelected === numRows;
  }

  // table selection
  public masterToggle() {
    this.isAllSelected() ?
        this.tableSelection.clear() :
        this.tableSource.data.forEach(row => this.tableSelection.select(row));
  }

  // update after delay
  public async _update(delay: number = 0)
  {
    await this.tools.delay(delay);
    this.init(true)
    if(this.selected_notification)
    {
      this.selected_notification = await this.aggregateService.getAggregateById(this.selected_notification.id, 'notification', new Notification());
      if(this.selected_notification.aggregate_type == 'device' && this.selected_notification.aggregate_id)
      {
        this.selected_aggregate = await this.aggregateService.getAggregateById(this.selected_notification.aggregate_id, 'device', new Device());
        this.loadAggregateAndAttributes();
      }
      else if(this.selected_notification.aggregate_type == 'devicegroup' && this.selected_notification.aggregate_id)
      {
        this.selected_aggregate = await this.aggregateService.getAggregateById(this.selected_notification.aggregate_id, 'devicegroup', new Devicegroup());
        this.loadAggregateAndAttributes();
      }
      else if(this.selected_notification.aggregate_type == 'location' && this.selected_notification.aggregate_id)
      {
        this.selected_aggregate = await this.aggregateService.getAggregateById(this.selected_notification.aggregate_id, 'location', new Location());
        this.loadAggregateAndAttributes();
      }
      else
      {
        this.selected_aggregate = null;
        this.selected_aggregate_attributes = null;
      }
    }
  }

  // Hanndle action from header
  public onAction(e)
  {
    this.loading = true;

    this.action_msg = "";
    if(e == "add")
    {
      if(this.level != 'user')
      {
        if(!this.selected_notification)
        {
          this.newNotification();
        }
      }
      else
      {
        this.action_msg = "t_not_allowed";
      }
    }
    else if(e == "delete")
    {
      if(this.selected_notification)
      {
        if(this.level != "user")
        {
          this.confirmDelete();
        }
        else
        {
          this.action_msg = "t_not_allowed";
        }
      }
    }
    else if(e == "refresh")
    {
      this.action_msg = '';
      this._update();
    }

    this.loading = false;
  }

  // callback click on row
  public async select(row)
  {
    this.loading = true;

    if(row.id)
    {
      
      this.selected_notification = await this.aggregateService.getAggregateById(row.id, 'notification', new Notification())
      if(this.selected_notification.aggregate_type == 'device' && this.selected_notification.aggregate_id)
      {
        this.selected_aggregate = await this.aggregateService.getAggregateById(this.selected_notification.aggregate_id, 'device', new Device());
        this.loadAggregateAndAttributes();
      }
      else if(this.selected_notification.aggregate_type == 'devicegroup' && this.selected_notification.aggregate_id)
      {
        this.selected_aggregate = await this.aggregateService.getAggregateById(this.selected_notification.aggregate_id, 'devicegroup', new Devicegroup());
        this.loadAggregateAndAttributes();
      }
      else if(this.selected_notification.aggregate_type == 'location' && this.selected_notification.aggregate_id)
      {
        this.selected_aggregate = await this.aggregateService.getAggregateById(this.selected_notification.aggregate_id, 'location', new Location());
        this.loadAggregateAndAttributes();
      }
      else
      {
        this.selected_aggregate = null;
        this.selected_aggregate_attributes = null;
      }
    }

    this.loading = false;
  }

  // new notification 
  public async newNotification()
  {
    this.loading = true;

    var notification = new Notification()
    notification.id = uuid();
    notification.name = "Nová událost";

    if(this.selected_location)
    {
      notification.location = this.selected_location.id;
    }
    
    this.aggregateService.updateAggregate(notification, 'create', 'notification', new Notification());
    notification.author_id = this.authService.user.id;
    this.notifications.push(notification);
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;
  }

  // notification save 
  public saveNotification(notification: Notification)
  {
    this.loading = true;

    if(notification.id)
    {
      this.aggregateService.updateAggregate(notification, 'update', 'notification', new Notification());
      //console.log(notification);
    }
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;
  }

  // delete notification
  public deleteNotification(id)
  {
    this.loading = true; 

    this.aggregateService.deleteAggregate(id, 'notification');

    var cnt = 0;
    this.notifications.forEach(e => {
      if(e.id == id)
      {
        this.notifications.splice(cnt, 1);
      }
      cnt += 1;
    });
    
    this.selected_notification = null;
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;

  }

  // confirm of delete
  public confirmDelete()
  {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {aggregate: this.selected_notification, aggregate_name: 'notification'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
      {
        this.deleteNotification(this.selected_notification.id);
      }
    });
  }

  public tabChanged(e)
  {
    this.selected_location = this.locations[e.index];
    this.init(true);
    this.cookieService.set('selected_location', this.selected_location.id, 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax")
  }  


  public openDevice(device)
  {
    if(device.id)
    {
      this.open_device.emit(device)
    }
  }

  public typeChanged(e)
  {
    this.selected_aggregate = null;
    this.selected_notification.aggregate_id = '';
    this.selected_notification.attribute = null;
    this.selected_notification.condition = "equal"
    this.selected_notification.value = "";
  }

  // assign aggregate
  public async selectAggregate()
  {
    var dialogRef: any = null;
    if (this.selected_notification.aggregate_type == 'device')
    {
      dialogRef = this.dialog.open(SelectAggregateDialogComponent, {
        width: '400px',
        data: {sys_name: 'device', name: 'devices', _class: new Device(), filter: ["location", this.selected_notification.location]}
      });
    }
    else if (this.selected_notification.aggregate_type == 'location')
    {
      dialogRef = this.dialog.open(SelectAggregateDialogComponent, {
        width: '400px',
        data: {sys_name: 'location', name: 'location', _class: new Location(), filter: ["id", this.selected_notification.location]}
      });
    }
    else
    {
      dialogRef = this.dialog.open(SelectAggregateDialogComponent, {
        width: '400px',
        data: {sys_name: 'devicegroup', name: 'devicegroups', _class: new Devicegroup(), filter: ["location", this.selected_notification.location]}
      });
    }
    

    dialogRef.afterClosed().subscribe(async result => {
      if(result)
      {
        this.selected_notification.aggregate_id = result.id;
        this.selected_aggregate = result;
        this.loadAggregateAndAttributes();
      }
    });
  }

  // delete assignet aggregate
  public deleteAggregate()
  {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
      {
        this.selected_aggregate = null;
        this.selected_notification.aggregate_id = '';
        this.selected_notification.attribute = null;
        this.selected_notification.condition = "equal"
        this.selected_notification.value = "";
      }
    });

    
  }

  public async loadAggregateAndAttributes()
  {
    if(!this.selected_aggregate.id)
    {
      this.selected_aggregate_attributes = [];
      return;
    }
    if(this.selected_notification.aggregate_type == 'device')
    {
      this.selected_aggregate_attributes = [];
      
      for(let r of this.selected_aggregate.read)
      {
        if (r.type == 'number' || r.type == 'boolean')
        {
          this.selected_aggregate_attributes.push(r)
        }
      }
      for(let r of this.selected_aggregate.write)
      {
        if (r.type == 'number' || r.type == 'boolean')
        {
          this.selected_aggregate_attributes.push(r)
        }
      }
    }
    // location
    else if (this.selected_notification.aggregate_type == 'location')
    {
      this.selected_aggregate_attributes = [];
      
      for(let r of this.selected_aggregate.read)
      {
        if (r.type == 'number' || r.type == 'boolean')
        {
          this.selected_aggregate_attributes.push(r)
        }
      }
      for(let r of this.selected_aggregate.write)
      {
        if (r.type == 'number' || r.type == 'boolean')
        {
          this.selected_aggregate_attributes.push(r)
        }
      }
    }
    // devicegroup
    else
    {
      var writes = await this.tools.getWritesFromGroup(this.aggregateService, this.selected_aggregate.id);
      var reads = await this.tools.getReadsFromGroup(this.aggregateService, this.selected_aggregate.id);
      this.selected_aggregate_attributes = [];
      this.selected_aggregate_attributes = writes.concat(reads);
    }
    //console.log(this.selected_aggregate_attributes);
  }

  public attributeChanged(e)
  {
    if(this.selected_notification.attribute.type == 'number')
    {
      if (this.selected_notification.attribute.min)
      {
        this.selected_notification.value = this.selected_notification.attribute.min;
      }
      else{
      this.selected_notification.value = 0;
      }
    }
    else
    {
      this.selected_notification.value = false;
    }

    this.selected_notification.condition = "equal"
  }

  public async manageUsers(notification: Notification)
  {
    var source: any[] = [];
    var users: User[] = await this.aggregateService.getAggregates('user', new User(), 0);
    var users_dict = {}

    for(let u of users)
    {
      if(!notification.users.includes(u.id))
      {
        if(u.locations_id.includes(this.selected_location.id) || u.level == 'root')
        {
            source.push(u.id);
        }
      }
      users_dict[u.id] = u;
    }

    const dialogRef = this.dialog.open(AggregateAssignDialogComponent, {
      width: '400px',
      data: {name: "users", aggregates: users_dict, source: source, target: notification.users}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        notification.users = result;
      }
    });
  }

}

