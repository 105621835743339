import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Device, Location, Devicegroup } from '../datatypes';
import { Tools } from '../tools';
import { trigger, style, animate, transition } from '@angular/animations';
import { Config } from '../config'
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { AuthService } from '../auth.service';
import { LanguageService } from '../language.service';
import { AggregateService } from '../aggregate.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { v4 as uuid } from 'uuid';
import { MapService } from '../map.service';
import { AddAttributeDialogComponent } from '../add-attribute-dialog/add-attribute-dialog.component';
import { DeleteAttributeDialogComponent } from '../delete-attribute-dialog/delete-attribute-dialog.component';
import { AggregateAssignDialogComponent } from '../aggregate-assign-dialog/aggregate-assign-dialog.component';
import { SelectAggregateDialogComponent } from '../select-aggregate-dialog/select-aggregate-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { EditAttributeDialogComponent } from '../edit-attribute-dialog/edit-attribute-dialog.component';


@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ]
})

export class DeviceComponent implements OnInit {
  lang: any;
  level: string = 'user';
  devices: Device[] = []
  device_type: number = 0;
  locations: Location[] = [];
  all_locations: Location[] = [];
  locations_dict = {};
  selected_device: Device;
  selected_device_location: Location;
  selected_location: Location;
  action_msg: string = ""
  tools = new Tools();
  cfg = new Config();
  loading: boolean = true;
  tab_index: number = 0;
  last_update: any = null;
  show_sensors: boolean = false;

  locations_loaded: boolean = false;

  tableColumns: string[] = ['reference', 'name', 'type', 'location', 'select']
  tableSource: any;
  tableSelection = new SelectionModel<Device>(true, []);

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input()
  public open_device: string = '';

  constructor(
    public authService: AuthService,
    public languageService: LanguageService,
    public aggregateService: AggregateService,
    public dialog: MatDialog,
    public mapService: MapService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.lang = this.languageService.language_data;
    this.level = this.authService.user.level;

    this.init()
  }

  public async init(update: boolean = false)
  {
    this.loading = true;

    if(!update)
    {
      this.locations_loaded = false;
    }

    this.all_locations = await this.aggregateService.getAggregates('location', new Location(), 0);
    for(let l of this.all_locations)
    {
      this.locations_dict[l.id] = l;
    }

    if(this.level == 'root')
    {
      this.locations = this.all_locations;
    }
    else
    {      
      this.locations = [];
      for(let l of this.all_locations)
      {
        if(this.authService.user.locations_id.includes(l.id))
        {
          this.locations.push(l);
        }
      }

    }

    if(!update)
    {
      var id = this.cookieService.get('selected_location');
      this.selected_location = null;
      
      if(id)
      {
        this.tab_index = 0;

        for(let l of this.locations)
        {

          if(l.id == id)
          {
            this.selected_location = l;
            break;
          }

          this.tab_index++;
          
        }
        if(!this.selected_location)
        {
          this.selected_location = this.locations[0];
        }
      }
      else
      {
        this.selected_location = this.locations[0];
      }
    }

    var devices: Device[] = await this.aggregateService.getAggregates('device', new Device(), 0);

    this.devices = []
    if(this.selected_location)
    {
      for(let d of devices)
      {
        if (d.location == this.selected_location.id)
        {
          this.devices.push(d);
        }
      }
    }
    else
    {
      this.devices = devices;
    }

    if(!update)
    {
      this.locations_loaded = true;
    }
    
    this.updateTable()

    if(this.open_device)
    {
      this.select({id: this.open_device})
    }

    this.loading = false;
  }

  // update mat table data
  private updateTable()
  {
    this.tableSource = new MatTableDataSource(this.devices);
    this.tableSource.sort = this.sort;
    this.tableSource.paginator = this.paginator;
    this.tableSelection = new SelectionModel<Device>(true, []);
  }

  // table filter
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableSource.filter = filterValue.trim().toLowerCase();

    if (this.tableSource.paginator) {
      this.tableSource.paginator.firstPage();
    }
  }

  // table selection
  public isAllSelected() {
    const numSelected = this.tableSelection.selected.length;
    const numRows = this.tableSource.data.length;
    return numSelected === numRows;
  }

  // table selection
  public masterToggle() {
    this.isAllSelected() ?
        this.tableSelection.clear() :
        this.tableSource.data.forEach(row => this.tableSelection.select(row));
  }

  // update after delay
  public async _update(delay: number = 0)
  {
    await this.tools.delay(delay);
    this.init(true)
    if(this.selected_device)
    {
      this.selected_device = await this.aggregateService.getAggregateById(this.selected_device.id, 'device', new Device());
      this.last_update = await this.aggregateService.getLastUpdate(this.selected_device.id)
    }
  }

  // Hanndle action from header
  public async onAction(e)
  {
    this.loading = true;

    this.action_msg = "";
    if(e == "add")
    {
      if(this.level == 'root')
      {
        if(!this.selected_device)
        {
          await this.newDevice();
        }
      }
      else
      {
        this.action_msg = "t_not_allowed";
      }
    }
    else if(e == "delete")
    {
      if(this.selected_device)
      {
        if(this.level == "root")
        {
          this.confirmDelete();
        }
        else
        {
          this.action_msg = "t_not_allowed";
        }
      }
    }
    else if(e == "refresh")
    {
      this.action_msg = '';
      await this._update();
    }

    this.loading = false;
  }

  // callback click on row
  public async select(row)
  {
    this.loading = true;

    if(row.id)
    {
      this.selected_device = await this.aggregateService.getAggregateById(row.id, 'device', new Device())
      this.last_update = await this.aggregateService.getLastUpdate(this.selected_device.id)
      this.device_type = this.selected_device.type;
      this.selected_device_location = this.tools.getById(this.all_locations, this.selected_device.location);
    }

    this.loading = false;
  }

  // new device 
  public async newDevice()
  {
    var device = this.tools.newByType(0);
    device.id = uuid();
    device.name = "Nové zařízení";
    device.gps.lat = this.mapService.position[0];
    device.gps.lon = this.mapService.position[1];
    device.enabled = false;
    device.type = 0
    
    if(this.selected_location)
    {
      device.location = this.selected_location.id;
    }
    
    this.aggregateService.updateAggregate(device, 'create', 'device', new Device());
    this.devices.push(device);
    this._update(this.cfg.UPDATE_DELAY);
  }

  // device save 
  public saveDevice(device: Device)
  {
    this.loading = true;

    if(device.id)
    {
      this.aggregateService.updateAggregate(device, 'update', 'device', new Device());
    }
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;
  }

  // delete device
  public deleteDevice(id)
  {
    this.loading = true;

    this.aggregateService.deleteAggregate(id, 'device');

    var cnt = 0;
    this.devices.forEach(e => {
      if(e.id == id)
      {
        this.devices.splice(cnt, 1);
      }
      cnt += 1;
    });
    
    this.selected_device = null;
    this.last_update = null
    this.selected_device_location = null;
    this._update(this.cfg.UPDATE_DELAY);

    this.loading = false;

  }

  // confirm of delete
  public confirmDelete()
  {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {aggregate: this.selected_device, aggregate_name: 'device'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
      {
        this.deleteDevice(this.selected_device.id);
      }
    });
  }

  // marker moved, set new GPS
  public mapMarkerMoved(e)
  {
    if(e.coords)
    {
      this.selected_device.gps.lat = e.coords.lat;
      this.selected_device.gps.lon = e.coords.lng;
    }
  }

  public addAttribute()
  {
    const dialogRef = this.dialog.open(AddAttributeDialogComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result.read)
        {
          this.selected_device.read.push(result.attribute)
        }
        else
        {
          this.selected_device.write.push(result.attribute)
        }
      }
    })
  }

  public deleteAttribute()
  {
    const dialogRef = this.dialog.open(DeleteAttributeDialogComponent, {
      width: '400px',
      data: {aggregate: this.selected_device}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        if(result.read)
        {
          this.selected_device.read.splice(result.cnt, 1);
        }
        else
        {
          this.selected_device.write.splice(result.cnt, 1);
        }
      }
    });
  }

  public editAttribute(attr)
  {
    //console.log(attr);

    const dialogRef = this.dialog.open(EditAttributeDialogComponent, {
      width: '400px',
      data: {attribute: attr}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        //console.log(result)
      }
    });
  }

  public tabChanged(e)
  {
    this.selected_location = this.locations[e.index];
    this.init(true);
    try
    {
      this.cookieService.set('selected_location', this.selected_location.id, 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax")
    }
    catch(e)
    {
      this.cookieService.set('selected_location', '', 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax")
    }
  }

  public typeChanged(e)
  {
    var device = this.tools.newByType(e.value);

    this.selected_device.type = e.value
    this.selected_device.read = device.read;
    this.selected_device.write = device.write;
    this.selected_device.local_address = device.local_address;
    this.selected_device.gateway_address = device.gateway_address;
  }

  public async manageGroups(device: Device)
  {
    var source: any[] = [];
    var groups: Devicegroup[] = await this.aggregateService.getAggregates('devicegroup', new Devicegroup(), 0);
    var groups_dict = {}

    for(let g of groups)
    {
      if(!device.groups.includes(g.id))
      {
        if(this.selected_device_location.id == g.location)
        {
            source.push(g.id);
        }
      }
      groups_dict[g.id] = g;
    }

    const dialogRef = this.dialog.open(AggregateAssignDialogComponent, {
      width: '400px',
      data: {name: "devicegroups", aggregates: groups_dict, source: source, target: device.groups}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        device.groups = result;
      }
    });
  }

  // Location
  public selectLocation()
  {
    const dialogRef = this.dialog.open(SelectAggregateDialogComponent, {
      width: '400px',
      data: {sys_name: 'location', name: 'locations', _class: new Location()}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
      {
        this.selected_device.location = result.id;
        this.selected_device_location = result;
      }
    });
  }

  public deleteLocation()
  {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
      {
        this.selected_device.location = '';
        this.selected_device_location = null;
      }
    });

    
  }

  public hasGroup(row)
  {
    if(row.id)
    {
      var d = this.tools.getById(this.devices, row.id)
      try
      {
        if(!d.groups.length)
        {
          return true;
        }
      }
      catch(e)
      {
        return false
      }
    }
    return false;
  }

  public getMsg(e)
  {
    //console.log("RECIEVED_MSG", e);
    this.action_msg = e;
    
  }

  public getLoading(e)
  {
    this.loading = e;
  }

}
