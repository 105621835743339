import { HttpHeaders } from '@angular/common/http';

export const MAP_KEY = "AIzaSyD44GAePR8Lzn0Dw-dLSngJ5m6u0vrrz_0";

export class Config
{
  VERSION = "1.21.10r5"
  HTTP_OPTIONS = {
        headers: new HttpHeaders({
          'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        })
      };

    DEBUG: boolean = false;
    SITE: string = "portal.scheuermann.cz";  
    SECURE: boolean = true;

    // Server or API gateway connection string
    API_GATEWAY: string = "https://portal.scheuermann.cz:8080/";
    
    // File server
    FILE_SERVER: string = "https://localhost:8090/";

    AUTH_ID: string = "";
    AUTH_KEY: string = "";

    UPDATE_INTERVAL = 1000;

    // delay before list update in ms
    UPDATE_DELAY = 1000; 

    PAGE_LIMIT = 10;
    MAX_UPLOAD_SIZE = 25000000;
    MAP_TYPE = 'hybrid'
}