import { Component, OnInit, Inject } from '@angular/core';
import { LanguageService } from '../language.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-aggregate-assign-dialog',
  templateUrl: './aggregate-assign-dialog.component.html',
  styleUrls: ['./aggregate-assign-dialog.component.css']
})
export class AggregateAssignDialogComponent implements OnInit {
  public lang: any;
  public msg: string = "";
  public source: string[] = [];
  public target: string[] = [];

  constructor(
    public languageService: LanguageService,
    public dialogRef: MatDialogRef<AggregateAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit()
  {
    this.lang = this.languageService.language_data;
    this.source = JSON.parse(JSON.stringify(this.data.source))
    this.target = JSON.parse(JSON.stringify(this.data.target))
  }

    onNoClick(): void {
    this.dialogRef.close();
  }

  public assign(i: number)
  {
    this.target.push(this.source[i])
    this.source.splice(i, 1)
  }

  public free(i: number)
  {
    this.source.push(this.target[i])
    this.target.splice(i, 1)
  }

  onOkClick(): void
  {    
    this.dialogRef.close(this.target);
  }
}