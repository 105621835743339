import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';
import { AuthService } from '../auth.service';
import { HealthService } from '../health.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  title = 'Phantom portal';
  route = ''

  constructor(
    public authService: AuthService,
    public healthService: HealthService,
    private router: ActivatedRoute,
  )
  {}

  ngOnInit()
  {
    this.route = this.router.snapshot.paramMap.get('page');
    // Service for checking kgate server/API gateway health
    interval(2000).subscribe(x=> { this.healthService.checkHealth() } );
    
    // check cookies
    this.authService.checkCookies();
  }
}