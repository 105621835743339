import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './material';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth.service';
import { HealthService } from './health.service';
import { AggregateService } from './aggregate.service';
import { UserLoginComponent } from './user-login/user-login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DevicegroupComponent } from './devicegroup/devicegroup.component';
import { EventComponent } from './event/event.component';
import { UserComponent } from './user/user.component';
import { DeviceComponent } from './device/device.component';
import { LocationComponent } from './location/location.component';
import { LanguageService } from './language.service';
import { MainComponent } from './main/main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { HeaderComponent } from './header/header.component';
import { SystemConfigComponent } from './system-config/system-config.component';
import { AggregateListComponent } from './aggregate-list/aggregate-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SelectAggregateDialogComponent } from './select-aggregate-dialog/select-aggregate-dialog.component';
import { AgmCoreModule } from '@agm/core';
import { MAP_KEY } from './config';
import { AddAttributeDialogComponent } from './add-attribute-dialog/add-attribute-dialog.component';
import { DeleteAttributeDialogComponent } from './delete-attribute-dialog/delete-attribute-dialog.component';
import { GaugeComponent } from './gauge/gauge.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { AggregateAssignDialogComponent } from './aggregate-assign-dialog/aggregate-assign-dialog.component';
import { AttributeWriteComponent } from './attribute-write/attribute-write.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ScheduleComponent } from './schedule/schedule.component';
import { AddScheduleComponent } from './add-schedule/add-schedule.component';
import { DeviceDataComponent } from './device-data/device-data.component';
import { DatachartComponent } from './datachart/datachart.component';
import { EditAttributeDialogComponent } from './edit-attribute-dialog/edit-attribute-dialog.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing';
import { IndexComponent } from './index/index.component';
import { LocationDataComponent } from './location-data/location-data.component';

enableProdMode();


@NgModule({
  declarations: [
    AppComponent,
    UserLoginComponent,
    DashboardComponent,
    DevicegroupComponent,
    EventComponent,
    UserComponent,
    DeviceComponent,
    LocationComponent,
    MainComponent,
    HeaderComponent,
    SystemConfigComponent,
    AggregateListComponent,
    ChangePasswordDialogComponent,
    DeleteDialogComponent,
    ConfirmDialogComponent,
    SelectAggregateDialogComponent,
    AddAttributeDialogComponent,
    DeleteAttributeDialogComponent,
    GaugeComponent,
    AggregateAssignDialogComponent,
    AttributeWriteComponent,
    ScheduleComponent,
    AddScheduleComponent,
    DeviceDataComponent,
    DatachartComponent,
    EditAttributeDialogComponent,
    IndexComponent,
    LocationDataComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    DemoMaterialModule,
    ReactiveFormsModule ,
    LayoutModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    AgmCoreModule.forRoot({
      apiKey: MAP_KEY,
      libraries: ['places']
    }),
    NgxGaugeModule,
    MDBBootstrapModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    AuthService,
    HealthService,
    AggregateService,
    LanguageService,
  ],
  entryComponents: [
    DeleteDialogComponent,
    ChangePasswordDialogComponent,
    ConfirmDialogComponent,
    SelectAggregateDialogComponent,
    AddAttributeDialogComponent,
    DeleteAttributeDialogComponent,
    AggregateAssignDialogComponent,
    EditAttributeDialogComponent,
    AddScheduleComponent,
    IndexComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
