import { Component, OnInit, Inject } from '@angular/core';
import { Attribute } from '../datatypes';
import { LanguageService } from '../language.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-attribute-dialog',
  templateUrl: './delete-attribute-dialog.component.html',
  styleUrls: ['./delete-attribute-dialog.component.css']
})
export class DeleteAttributeDialogComponent implements OnInit {
  public lang: any;
  public attribute: Attribute = new Attribute("", "", "string", "");
  public read: Attribute[] = [];
  public write: Attribute[] = [];
  public msg: string = "";

  public is_read: boolean;
  public cnt: number;
  public selected: Attribute;

  constructor(
    public languageService: LanguageService,
    public dialogRef: MatDialogRef<DeleteAttributeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit()
  {
    this.lang = this.languageService.language_data;
    this.read = this.data.aggregate.read;
    this.write = this.data.aggregate.write;
  }

  public selectAttribute(type: string, i: number)
  {
    if(type == 'read')
    {
      this.selected = this.read[i];
      this.is_read = true;
      this.cnt = i;
    }
    else
    {
      this.selected = this.write[i];
      this.is_read = false;
      this.cnt = i;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void
  {
    this.msg = "";
    var result = {"read": false, "cnt": 0};
    
    result.read = this.is_read;
    result.cnt =this.cnt;

    
    this.dialogRef.close(result);
  }
}