import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, Input, Attribute, Output, EventEmitter } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.css']
})
export class GaugeComponent implements OnInit {
  public lang: any;

  public gaugeType: string = "arch";
  public gaugeThick: number = 5;
  public gaugeSize: number = 110;
  public gaugeCap: string = "round"
  public gaugeColor: string = "rgba(14, 91, 165, 1)";
  public gaugeColorWarn: string = "rgba(244, 67, 54, 1)";

  public warn_coef: number = 0.7;

  @Input()
  public attribute: Attribute;
  @Input()
  public editable: Boolean = false;

  @Output('editAttribute')
  editAttribute: EventEmitter<Attribute> = new EventEmitter<Attribute>();

  constructor(
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.lang = this.languageService.language_data;
  }

  public edit(attr)
  {
    this.editAttribute.emit(attr);
  }

}
