import { Component, Inject, OnInit } from '@angular/core';
import  {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval } from 'rxjs';
import { AggregateService } from '../aggregate.service';
import { Md5 } from 'ts-md5/dist/md5';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.css']
})
export class ChangePasswordDialogComponent implements OnInit {

  public password1: string = "";
  public password2: string = "";
  public msg: string = "";

  public lang: any;

  private updateInterval = interval(100);
  private updateSubscription;

  constructor(public aggregateService: AggregateService,
    public langualeService: LanguageService,
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.updateSubscription = this.updateInterval.subscribe(x => { this._update() })
     }

  ngOnInit()
  {
    this.lang = this.langualeService.language_data
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  private password_md5(password: string)
  {
    var password_hash;
    var md5 = new Md5();
    password_hash = md5.appendStr(password).end().toString();

    return  password_hash;
  }

  public on_close(): void 
  {
    if (this.password1)
    {
      if(this.password1 == this.password2)
      {
        this.dialogRef.close(this.password_md5(this.password1));
      }
      else
      {
        this.msg = "t_password_not_match";
      }
    }
    else{
      this.msg = "t_password_empty";
    }
    
  }

  private async _update()
  {
   
  }

}

