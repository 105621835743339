import { Injectable } from '@angular/core';
import { Config, MAP_KEY } from './config';
import { HttpClient, HttpParameterCodec } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private cfg = new Config();
  private MAP_URL = "https://nominatim.openstreetmap.org/search?format=json&q=";
  public position: number[] = [0, 0];

  constructor(private http: HttpClient) { }

  public get_address_info(address: string): Observable<any> {

    var url = this.MAP_URL + encodeURIComponent(address);
    // console.log(url)

    return this.http.get(url).pipe(map(response => <any> response));
  }

  private _get_position(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }

  public fetch_position()
  {
    this._get_position().then(pos=>
      {
        // console.log("Latitude: " + pos.lat + ", Longitude " + pos.lng);
        this.position[0] = pos.lat;
        this.position[1] = pos.lng
      });
    
  }
  
}