import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import 'chartjs-plugin-dragdata/dist/chartjs-plugin-dragdata.js'
import { Schedule } from '../datatypes';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  chartLabels: Array<any> = [];
  chartDatasets: Array<any> = [{ data: [], label: '' }];
  chartOptions: any = {};
  chartType: string = 'line';
  chartColors: Array<any> = []

  @Input()
  schedule: Schedule = new Schedule();

  @Output('scheduleChanged')
  changedSchedule: EventEmitter<Schedule> = new EventEmitter<Schedule>();

  ngOnInit()
  {

    //console.log(this.schedule)

    this.chartDatasets[0].label = this.schedule.attr.name;

    this.chartLabels = this.schedule.time_steps;

    if(this.schedule.attr.type == 'number')
    {
      this.chartColors = [{backgroundColor: 'rgba(14, 90, 164, .3)', borderColor: 'rgba(14, 90, 164, .8)', borderWidth: 2,}];
      for(let a of this.schedule.attribute_steps)
      {
        this.chartDatasets[0].data.push(a.value)
      }
      this.chartOptions = {
        responsive: true,
        dragData: true,
        onDragEnd: (e, datasetIndex, index, value) => { this.drag(datasetIndex, index, value) },
        dragX: false,
        dragDataRound: 0,
        plugins: {
          datalabels: {
              display: false,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: this.schedule.attr.min,
              max:this.schedule.attr.max
            }
          }]
        }
      };
    }
    else
    {
      this.chartColors = [{backgroundColor: 'rgba(255, 00, 00, .3)', borderColor: 'rgba(255, 0, 0, .8)', borderWidth: 2,}];
      for(let a of this.schedule.attribute_steps)
      {
        if(a.value)
        {
          this.chartDatasets[0].data.push(2)  
        }
        else
        {
          this.chartDatasets[0].data.push(1)
        }
        
      }
      this.chartType = 'bar';
      this.chartOptions = {
        responsive: true,
        dragData: true,
        onDragEnd: (e, datasetIndex, index, value) => { this.drag(datasetIndex, index, value) },
        dragX: false,
        dragDataRound: 0,
        plugins: {
          datalabels: {
              display: false,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              min: 0.9,
              max: 2.1,
              display: false
            }
          }]
        }
      };
    }
    
  }

  public drag(datasetIndex, index, value)
  {
    if(this.schedule.attr.type == 'number')
    {
      this.schedule.attribute_steps[index].value = value;
    }
    else
    {
      if(value > 1)
      {
        this.schedule.attribute_steps[index].value = true;
      }
      else
      {
        this.schedule.attribute_steps[index].value = false;
      }

      this.chartDatasets = [{ data: [], label: '' }];
      this.chartDatasets[0].label = this.schedule.attr.name;
      for(let a of this.schedule.attribute_steps)
        {
          if(a.value)
          {
            this.chartDatasets[0].data.push(2)  
          }
          else
          {
            this.chartDatasets[0].data.push(1)
          }
          
        }
    }

    //console.log(this.schedule)
    
    this.changedSchedule.emit(this.schedule);
  }

}