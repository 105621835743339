import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Location, Device, Devicegroup, Attribute } from '../datatypes';
import { Config } from '../config';
import { Tools } from '../tools';
import { MapService } from '../map.service';
import { MatDialog } from '@angular/material/dialog';
import { AggregateService } from '../aggregate.service';
import { LanguageService } from '../language.service';
import { AuthService } from '../auth.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ]
})

export class DashboardComponent implements OnInit {

  lang: any;
  level: string = 'user';
  devicegroups: Devicegroup[] = []
  devicegroup_type: number = 0;
  group_devices: Device[];
  locations: Location[] = [];
  all_locations: Location[] = [];
  all_attributes: Attribute[] = [];
  location_devices: Device[] = [];
  locations_dict = {};
  loading: boolean = true;
  tab_index: number = 0;

  selected_location: Location;
  selected_group: Devicegroup;
  random_device: Device;
  sensor_devices: Device[] = [];
  action_msg: string = ""
  tools = new Tools();
  cfg = new Config();

  selected_sensor_device: any = null;

  locations_loaded: boolean = false;

  @Output("open_device")
  public open_device: EventEmitter<Device> = new EventEmitter<Device>();

  constructor(
    public authService: AuthService,
    public languageService: LanguageService,
    public aggregateService: AggregateService,
    public dialog: MatDialog,
    public mapService: MapService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.lang = this.languageService.language_data;
    this.level = this.authService.user.level;

    this.init()
  }

  public async init(update: boolean = false)
  {
    if(!update)
    {
      this.locations_loaded = false;
    }

    this.all_locations = await this.aggregateService.getAggregates('location', new Location(), 0);
    for(let l of this.all_locations)
    {
      this.locations_dict[l.id] = l;
    }

    if(this.level == 'root')
    {
      this.locations = this.all_locations;
    }
    else
    {      
      this.locations = [];
      for(let l of this.all_locations)
      {
        if(this.authService.user.locations_id.includes(l.id))
        {
          this.locations.push(l);
        }
      }

    }

    if(!update)
    {
      var id = this.cookieService.get('selected_location');
      this.selected_location = null;
      
      if(id)
      {
        this.tab_index = 0;

        for(let l of this.locations)
        {
          if(l.id == id)
          {
            this.selected_location = l;
            break;
          }

          this.tab_index++;
          
        }
        if(!this.selected_location)
        {
          this.selected_location = this.locations[0];
        }
      }
      else
      {
        this.selected_location = this.locations[0];
      }
    }

    var devicegroups: Devicegroup[] = await this.aggregateService.getAggregates('devicegroup', new Devicegroup(), 0);

    this.devicegroups = []
    if(this.selected_location)
    {
      for(let d of devicegroups)
      {
        if (d.location == this.selected_location.id)
        {
          this.devicegroups.push(d);
        }
      }
    }
    else
    {
      this.devicegroups = devicegroups;
    }

    if(!update)
    {
      this.locations_loaded = true;
    }

    this.location_devices = await this.tools.devicesFromLocation(this.aggregateService, this.selected_location.id)

    if(this.selected_location && this.devicegroups.length)
    {
      this.selected_group = this.devicegroups[0];
      this.group_devices = await this.tools.devicesFromGroup(this.aggregateService, this.selected_group.id);
      this.all_attributes = await this.tools.getWritesFromGroup(this.aggregateService, this.selected_group.id);
    }

    //get random device
    this.random_device = this.getRandomDevice(this.location_devices);
    this.sensor_devices = this.getSensorDevices(this.location_devices);
    this.loading = false
  }

  public async onAction(e)
  {
    this.loading = true;

    this.action_msg = "";
    if(e == "refresh")
    {
      this.action_msg = '';
      await this.init();
    }

    this.loading = false;
  }

  public tabChanged(e)
  {
    this.selected_location = this.locations[e.index];
    this.init(true);
    this.cookieService.set('selected_location', this.selected_location.id, 0, '/', this.cfg.SITE, this.cfg.SECURE, "Lax")
    // console.log(this.selected_location)
  }  

  public async groupChanged(e)
  {
    this.loading = true;

    this.selected_group = e.value;
    this.group_devices = await this.tools.devicesFromGroup(this.aggregateService, this.selected_group.id)
    this.all_attributes = await this.tools.getWritesFromGroup(this.aggregateService, this.selected_group.id)

    this.loading = false;
  }

  private updateDeviceAttr(attribute, device)
  {
    if(!device.write)
    {
      return false;
    }

    var idx = 0
    for(let attr of device.write)
    {
      if(attr.name == attribute.name)
      {
        device.write[idx].value = attribute.value;
      }
      idx++;
    }

    return device
  }

  public async groupControl(attributes)
  {

    this.loading = true;

    var devices: any[] = []
    for(let attr of attributes)
    {

      for( let d of this.group_devices)
      {
        var _d = this.updateDeviceAttr(attr, d);
        if (_d)
        {
          devices.push(_d);
        }
      }
    }

    for(let d of devices)
    {
      //update
      this.aggregateService.updateAggregate(d, 'update', 'device', new Device());
    }
    await this.aggregateService.getAggregates('devicegroup', new Devicegroup(), 0);
    this.loading = false;

  }

  public openDevice(device)
  {
    if(device.id)
    {
      this.open_device.emit(device)
    }
  }

  public getRandomDevice(devices: any[])
  {
    var suffled = this.tools.suffleArray(devices)

    for(let d of suffled)
    {
      if(d.read.length > 1)
      {
        return d;
      }
    }

    return null;
  }

  public getSensorDevices(devices: any[])
  {
    var _devices = []
    for(let d of devices)
    {
      if(d.read.length > 1)
      {
        _devices.push(d);
      }
    }

    return _devices;
  }

}
