import { Injectable } from '@angular/core';
import { CZ, EN } from './lang';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public language_data: any;
  constructor() { 
    this.language_data = CZ;
  }

  public set_lang(lang: string)
  {
    if(lang == "CZ")
    {
      this.language_data = CZ;
    }
    else if(lang == "EN")
    {
      this.language_data = EN;
    }
    else
    {
      this.language_data = CZ;
    }
    
  }
}
