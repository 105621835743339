import { Component, OnInit, ɵNG_INJECTOR_DEF } from '@angular/core';
import { AuthService } from '../auth.service';
import { AggregateService } from '../aggregate.service';
import { LanguageService } from '../language.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { SafeUrl } from '@angular/platform-browser';
import { DomSanitizer } from "@angular/platform-browser";
import { HealthService } from '../health.service';
import { Config } from '../config';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-system-config',
  templateUrl: './system-config.component.html',
  styleUrls: ['./system-config.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'cs-CZ'}
  ]
})

export class SystemConfigComponent implements OnInit {
  public cfg: Config = new Config();
  
  public show: boolean = false;
  public lang: any;
  public include_reference : boolean = false;
  public e_error_msg: string;
  public i_error_msg: string;
  public e_pending: boolean = false;
  public i_pending: boolean = false;
  public download_url = "";
  public download_safe_url: any = null;
  public e_finished = false;
  public i_finished = false;
  public i_confirm: boolean = false;
  public e_skip = [];

  public outage_date = "";
  public outage_time = "";

  // "AUTH_ID": "490cf1ed-b5df-4da4-88dd-1e24d086e461",
  // "AUTH_KEY": "549fdddd10a1d075c9f7a06e24450f35",

  public e_auth_id: string = '';
  public e_auth_key: string = '';

  public i_auth_id: string = '';
  public i_auth_key: string = '';

  public import_file: any = null;
  public snapshot: any = null;

  constructor(public sanitizer: DomSanitizer,
    public healthService: HealthService,
    public authService: AuthService,
    public aggregateService: AggregateService,
    public languageService: LanguageService) { }

  ngOnInit() {
    this.lang = this.languageService.language_data;
    this.e_pending = false;
    this.e_finished = false;
    this.e_error_msg = '';
    this.download_safe_url = null;
    this.download_url = '';
    this.e_auth_id = '';
    this.e_auth_key = '';
    
    this.i_pending = false;
    this.i_finished = false;
    this.i_error_msg = '';
    this.i_confirm = false;
    this.import_file = null;
    this.snapshot = null;
    this.i_auth_id = '';
    this.i_auth_key = '';

    this.outage_date = this.healthService.health.outage.date;
    this.outage_time = this.healthService.health.outage.time;

  }

  public async export_snapshot(auth_id: string, auth_key: string)
  {
    this.e_error_msg = "";
    this.e_finished = false;
    
    if (this.download_url)
    {
      URL.revokeObjectURL(this.download_url)
    }

    if(!auth_id)
    {
      this.e_error_msg = "No auth_id";
      return;
    }

    if(!auth_key)
    {
      this.e_error_msg = "No auth_key";
      return;
    }

    this.e_pending = true;
    var result = await this.aggregateService.exportSnapshot(auth_id, auth_key, this.e_skip, this.include_reference);
    if(result.result == "export")
    {
      var str_data = JSON.stringify(result.jdata)
      var blob_file = new Blob([str_data], {type: 'text/plain'})


      this.download_url = URL.createObjectURL(blob_file);
      this.download_safe_url = this.sanitizer.bypassSecurityTrustUrl(this.download_url)
      this.e_finished = true;      
    }

    this.e_pending = false;

  }

  public async import_snapshot(auth_id: string, auth_key: string, snapshot: any)
  {
    this.i_error_msg = "";
    this.i_finished = false;

    if(!auth_id)
    {
      this.i_error_msg = "No auth_id";
      return;
    }

    if(!auth_key)
    {
      this.i_error_msg = "No auth_key";
      return;
    }

    if(!snapshot)
    {
      this.i_error_msg = "No file";
      return;
    }

    if(!this.i_confirm)
    {
      this.i_error_msg = "Not confirmed";
      return;
    }

    this.i_pending = true;
    var result = await this.aggregateService.importSnapshot(auth_id, auth_key, snapshot)
    if(result.result)
    {
      this.i_finished = true;
    }
    else if(result.error)
    {
      this.i_error_msg = "Error while import";
    }
    
    this.i_confirm = false;
    this.import_file = null;
    this.snapshot = null;
    this.i_pending = false;
  }

  public handle_file(files)
  {
    this.import_file = files[0];

    var _reader = new FileReader();
    _reader.readAsText(this.import_file)
    _reader.onload = () => {
      try{
        this.snapshot = JSON.parse(_reader.result as string);
      }
      catch(e)
      {
        this.snapshot = null;
      }
      
     }
  }
  
  
  // encode data to binary
  private _encode(s: string)
  {
    var out = [];
    for ( var i = 0; i < s.length; i++ ) {
      out[i] = s.charCodeAt(i);
    }
    return new Uint8Array( out );
  }

  public async setOutage()
  {
    var outage = {};
    if(this.outage_date)
    {
      outage['date'] = this.outage_date;
      if(this.outage_time)
      {
        outage['time'] = this.outage_time;
      }
      else
      {
        outage = {date: "", time: ""}
      }
    }
    else
    {
      outage = {date: "", time: ""}
    }

    var result = await this.aggregateService.setOutage(outage)

  }

}