import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Device, Location, Devicegroup } from '../datatypes';
import { Tools } from '../tools';
import { trigger, style, animate, transition } from '@angular/animations';
import { Config } from '../config'
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { LanguageService } from '../language.service';
import { AggregateService } from '../aggregate.service';
import { v4 as uuid } from 'uuid';
import { MapService } from '../map.service';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';


@Component({
  selector: 'app-location-data',
  templateUrl: './location-data.component.html',
  styleUrls: ['./location-data.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'cs-CZ'}
  ]
})

export class LocationDataComponent implements OnInit {
  lang: any;
  level: string = 'user';
  tools = new Tools();
  cfg = new Config();
  history: any[] = [];
  date_from: Date = null
  date_to: Date = null
  time_from: string = "";
  time_to: string = "";
  reads: any[] = [];
  writes: any[] = [];
  
  @Input()
  public locations: Location[] = [];

  @Output('msg')
  public msg: EventEmitter<string> = new EventEmitter<string>();

  @Output('loading')
  public loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public authService: AuthService,
    public languageService: LanguageService,
    public aggregateService: AggregateService,
    public dialog: MatDialog,
    public mapService: MapService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.lang = this.languageService.language_data;
    this.level = this.authService.user.level;

    this.init()
  }

  public init()
  {
    this.reads = [];
    this.writes = [];

    for(let r of this.locations[0].read)
    {
      if(r.type == 'number' || r.type == 'boolean')
      {
        this.reads.push({
          "name": r.name,
          "desc": r.desc,
          "unit": r.unit,
          "timeline": [],
          "data": []
        })
      }
    }

    for(let w of this.locations[0].write)
    {
      if(w.type == 'number' || w.type == 'boolean')
      {
        this.writes.push({
          "name": w.name,
          "desc": w.desc,
          "unit": w.unit,
          "timeline": [],
          "data": []
        })
      }
    }
  }

  public load()
  {
    this.init();

    // reads
    for(let r of this.reads)
    {
      for(let h of this.history)
      {
        r.timeline.push(h[1]);
        for(let _r of h[0].read)
        {
          if(_r.name == r.name)
          {
            r.data.push(_r.value);
          }
        }

      }
    }

    // writes
    for(let w of this.writes)
    {
      for(let h of this.history)
      {
        w.timeline.push(h[1]);
        for(let _w of h[0].write)
        {
          if(_w.name == w.name)
          {
            w.data.push(_w.value);
          }
        }

      }
    }
  }

  public async setPeriod()
  {

    // checks
    if(!this.date_from || !this.date_to)
    {
      this.msg.emit("missing_date");
      return;
    }

    if(!this.time_from || !this.time_to)
    {
      this.msg.emit("missing_time");
      return;
    }

    if (this.date_to < this.date_from)
    {
      this.msg.emit("date_from_older");
      return;
    }

    if(this.date_from.toString() == this.date_to.toString())
    {
      if(this.tools.biggerTime(this.time_from, this.time_to))
      {
        this.msg.emit("time_from_older")
        return;
      }
    }

    this.msg.emit("");

    var _from = moment(this.date_from).format("DD/MM/YYYY") +" "+this.time_from;
    var _to = moment(this.date_to).format("DD/MM/YYYY") +" "+this.time_to;

    this.loading.emit(true);

    this.history = await this.aggregateService.getLocationHistory(this.locations[0].id, _from, _to)
    if(!this.history.length)
    {
      this.msg.emit("no_data");
    }
    else
    {
      this.load();
    }

    this.loading.emit(false);
  }

}
